import { useCallback, useMemo, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { Image, Loader } from '@storybook';
import { API_URL } from 'constant';
import { useNetwork, useNotification } from 'hooks';
import { PaymentCardMethodsState } from 'global-stores';
import { usePaymentCard } from 'views/routes-children';
import { DropPaymentOptions } from '../payment-details/components/DropPaymentOption';
import { Cards } from '../type';
import { DefaultCardType } from '../constant';

export const CardListView = ({
	selectedLabel = 'Primary',
}: {
	selectedLabel?: string;
}) => {
	const paymentCardMethodsState = useRecoilValue(PaymentCardMethodsState);
	const [isLoading, setIsLoading] = useState(false);
	const [loadingId, setLoadingId] = useState('');
	const setPaymentCardMethodsState = useSetRecoilState(PaymentCardMethodsState);
	const { remove: removeCardDetail, post } = useNetwork();
	const { successNotification ,errorNotification} = useNotification();
	const { getPaymentCardsApi } = usePaymentCard();

	const { cards = [], default: defaultCard } = useMemo(
		() => paymentCardMethodsState,
		[paymentCardMethodsState]
	);

	const cardImage = useCallback((brand: string) => {
		switch (brand) {
			case 'Visa':
				return 'visa-card.svg';
			case 'MasterCard':
				return 'master-card.svg';
			case 'Discover':
				return 'discover-card.svg';
			case 'MaestroCard':
				return 'maestro-card.svg';
			case 'American Express':
				return 'american-express-card.svg';
			default:
				return 'default-card.svg';
		}
	}, []);

	const current = new Date();
	const year = current.getFullYear();
	const month = current.getMonth();

	const isCardExpired = useCallback(
		(card: any) =>
			card?.exp_year < year ||
			card?.exp_month > 12 ||
			//Gaurav: < replace to <= because getMonth() returns the month (0 to 11) of a date.
			(card?.exp_year === year && card?.exp_month <= month),
		[month, year]
	);

	const handleDefault = useCallback(
		async (id: string) => {
			setIsLoading(true);
			setLoadingId(id);
			const payload = {
				method: DefaultCardType.Card,
				data: {
					id,
				},
			};
			const defaultResp = await post(API_URL.Payment_Methods, payload);
			const { success } = defaultResp ?? {};
			if (success) {
				getPaymentCardsApi();
				successNotification('Card set as primary successfully!');
			}
			setLoadingId('');
			setIsLoading(false);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[]
	);

	const handleDelete = useCallback(
		async (id: string) => {
			setIsLoading(true);
			const resp = await removeCardDetail(`${API_URL.BUSINESS_CARD}/${id}`);
			const { deleted } = resp ?? {};
			if (deleted) {
				const newCardArray = cards.filter((items: Cards) => items.id !== id);
				setPaymentCardMethodsState((prevState: any) => ({
					...prevState,
					cards: newCardArray,
				}));
				successNotification('Card Deleted Successfully!');
			}else{
				errorNotification('Please add more cards to delete this card')
			}
			setIsLoading(false);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[cards, setPaymentCardMethodsState]
	);

	return cards.map((card: Cards) => {
		const isExpired = isCardExpired(card);
		return (
			<div
				className={`payment-save-card-list__saved  ${
					card.id === paymentCardMethodsState?.default?.detail?.id
						? 'payment-save-card-list__saved_primary'
						: ''
				}`}
				key={`card_${card.id}`}
			>
				<label>
					<div className={`payment-save-card-list__saved_card`}>
						<Image
							fileName={cardImage(card?.brand)}
							className="payment-save-card-list__card-icon"
						/>
						<div className="payment-card-detail payment-card-list">
							<div className="payment-card-detail-row">
								<div className="payment-save-card-list__number">
									•••• •••• •••• {card.last4}
								</div>
								<div className="payment-save-card-list__expire">
									Expires {card?.exp_month}/{card?.exp_year}
								</div>
							</div>
							<div className="payment-save-card-primary-row">
								{card.id === paymentCardMethodsState?.default?.detail?.id && (
									<div className="payment-save-card-primary">
										{selectedLabel}
									</div>
								)}
							</div>
							{/* Gaurav :  Remove from above because it was overlapping with selected */}
							{isExpired ? (
								<div className="payment-save-card-list__expired">Expired</div>
							) : (
								<></>
							)}

							<div className="payment-card-detail-option-row">
								{card.id !== paymentCardMethodsState?.default?.detail?.id &&
									(isLoading && loadingId === card?.id ? (
										<Loader type="loader" dimension={26} />
									) : (
										<DropPaymentOptions
											handleView={() => {
												handleDefault(card.id);
											}}
											handleDelete={() => handleDelete(card.id)}
											handleUseFlow={() => null}
											cardId={card.id}
											isExpired={isExpired}
											defaultCardId={defaultCard?.detail?.id}
										/>
									))}
							</div>
						</div>
					</div>
				</label>
			</div>
		);
	});
};
