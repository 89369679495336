import { Image } from '@storybook';
import { FC } from 'react';
import './biometric-auth-info.scss';

export const BiometricAuthInfo: FC = () => {
    return (
        <div className="biometric-info-modal__main">
            <div className="biometric-info-modal__body">
                <div className="biometric-info-modal__image">
                    <Image fileName="biomatric-info.svg" />
                </div>
                <div className="biometric-info-modal__title-wrapper">
                    <span className="title">Biometric details not found</span>
                    <div className="sub-title">
                        <span>To register for biometric login, you need to add your biometric details on your device first. Follow the instructions below for your operating system:</span>
                    </div>
                </div>
                <div className="biometric-info-modal__details-wrapper">
                    <div className='biometric-info-modal__details'>
                        <div>
                            <span className='biometric-info-modal__details-title'>
                                <b>MacOS</b>
                                <a target='_blank' rel="noreferrer" href='https://support.apple.com/en-in/guide/mac-help/mchl16fbf90a/mac'>
                                    <i className="ri-links-fill"></i>
                                </a>
                            </span>
                        </div>:
                        <ol className='biometric-info-modal__details-list'>
                            <li>Choose <b>Apple</b> menu.</li>
                            <li>Click on <b>System Settings</b> then <b>Touch ID & Password</b>.</li>
                            <li>Click on <b>Add a Fingerprint</b> and follow the prompts.</li>
                        </ol>
                    </div>
                    <div className='biometric-info-modal__details'>
                        <div>
                            <span className='biometric-info-modal__details-title'>
                                <b>Windows</b>
                                <a target='_blank' rel="noreferrer" href='https://support.microsoft.com/en-au/windows/configure-windows-hello-dae28983-8242-bb2a-d3d1-87c9d265a5f0'>
                                    <i className="ri-links-fill"></i>
                                </a>
                            </span>
                        </div>:
                        <ol className='biometric-info-modal__details-list'>
                            <li>Go to <b>Settings</b> app on your device.</li>
                            <li>Click on <b>Accounts</b> then <b>Sign-in options</b>.</li>
                            <li>Under <b>Ways to sign in</b> , select <b>Facial recognition</b> or <b>Fingerprint recognition</b> and follow the prompts.</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    )

}