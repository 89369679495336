import { atom } from 'recoil';
import { v4 } from 'uuid';

export interface IEventUser {
	status: string;
	name: string;
	phone: string;
	email: string;
	image: string;
	sessionId: string;
	_id: string;
	changedAt: string;
}

export interface IEventsState {
	name: string;
	image: string;
	date: string;
	description: string;
	businessId?: string;
	qrIds: string[];
	_id: string;
	address: string;
	users: IEventUser[];
}

export const EventsState = atom<IEventsState[]>({
	key: v4(),
	default: [],
});

export const SelectedEvent = atom<IEventsState | null | object>({
	key: v4(),
	default: null,
});

export const EventRadioType = atom<string>({
	key: v4(),
	default: 'select',
});

export const EventConfiguration = atom<any>({
	key: v4(),
	default: [],
});


