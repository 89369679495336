import { IEventUser } from 'views/events/store';
import { FC, useMemo, useState } from 'react';
import './event-user-card.scss';
import { Loader } from '@storybook';

interface IEventCard {
	handleClose: () => void;
	image: string;
	name: string;
	email: string;
	phone: string;
	status: string;
	sessionId: string;
	onViewSession: (users: IEventUser) => () => void;
	users: IEventUser;
	qrIds: string[];
	onStatusChange: (
		qrIds: string[],
		id: string,
		type: 'REJECTED' | 'APPROVED'
	) => Promise<void>;
}
export const EventUserCard: FC<IEventUser & IEventCard> = ({
	image,
	name,
	phone,
	email,
	status,
	onViewSession,
	users,
	onStatusChange,
	qrIds,
	_id,
}) => {
	const [isAPiLoading, setApiLoading] = useState(false);

	const renderStatus = useMemo(() => {
		switch (status) {
			case 'APPROVED':
				return <button className="EventUserCard--approved">Approved</button>;

			case 'REJECTED':
				return <button className="EventUserCard--rejected">Rejected</button>;
			default:
				return !isAPiLoading ? (
					<>
						<button
							disabled={isAPiLoading}
							className="EventUserCard--reject"
							onClick={() => {
								setApiLoading(true);
								onStatusChange(qrIds, _id, 'REJECTED').finally(() => {
									setApiLoading(false);
								});
							}}
						>
							Reject
						</button>
						<button
							disabled={isAPiLoading}
							className="EventUserCard--approve"
							onClick={() => {
								setApiLoading(true);
								onStatusChange(qrIds, _id, 'APPROVED').finally(() => {
									setApiLoading(false);
								});
							}}
						>
							Approve
						</button>
					</>
				) : (
					<Loader dimension={20} type="loader" />
				);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, qrIds, isAPiLoading]);

	return (
		<section className="EventUserCard">
			<div className="EventUserCard--img">
				<img src={image} alt={name} />
			</div>
			<div className="EventUserCard--info">
				<div className="EventUserCard--name">
					<p>{name}</p>
					<div
						className="EventUserCard--session"
						onClick={onViewSession(users)}
					>
						<i className="ri-eye-line"></i>
						<p>View session</p>
					</div>
				</div>

				<div className="EventUserCard--name">
					<p>{phone}</p>
				</div>

				<div className="EventUserCard--name">
					<p>{email}</p>
				</div>
				<div className="EventUserCard--btns">{renderStatus}</div>
			</div>
		</section>
	);
};
