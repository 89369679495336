export const documentType = {
	allFile: { id: 'allFile', label: 'All' },
	document: { id: 'document', label: 'Document' },
	certificate: { id: 'certificate', label: 'Certificate' },
	combinePdf: { id: 'combinePdf', label: 'All combined in one file' },
	voidedDocuments: {
		id: 'document',
		label: 'Download all documents separately',
	},
};

export const Message = {
	// Messages displayed to the user for different document-related events
	DOWNLOAD_SUCCESS:
		'Your documents are being prepared for open and will be automatically open in approx 30 seconds.',
	DOCUMENT_NOT_FOUND: 'Document not found.',
};

export class Memory {
	// Stores the timestamp for multi-document download operations
	private static multiDocDownload: number;

	// Sets the timestamp for multi-document download and returns it
	static setTimeStamp(timestamp: number) {
		Memory.multiDocDownload = timestamp;
		return Memory.multiDocDownload;
	}

	// Retrieves the stored timestamp for multi-document download
	static getTimeStamp() {
		return Memory.multiDocDownload;
	}
}

export const DOWNLOAD_RESP_TYPE = 'certificate-download'; // Response type identifier for certificate downloads
