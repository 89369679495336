import { ICountries } from '@storybook/country-code';

import { ChangeEvent, KeyboardEvent, useEffect, useState } from 'react';
import { CountryCode, Button } from '@storybook';

import './input.scss';

interface Props {
	label: string;
	inputType: 'text' | 'number' | 'password' | 'date' | 'datetime-local';
	placeholder: string;
	handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
	value?: string | number;
	isCountryCodeVisible?: boolean;
	handleChangeCountry?: (e: ICountries) => void;
	isShowButton?: boolean;
	handleClickBtn?: () => void;
	errorMessage?: string;
	isError?: boolean;
	handleClearField?: () => void;
	handleKeyPress?: (e: KeyboardEvent<HTMLInputElement>) => void;
	allowClear?: boolean;
	id?: string;
	handleBlur?: () => void;
	handleFocus?: () => void;
	prefixIcon?: string;
	disabled?: boolean;
	suffixIcon?: string;
	handleSuffixIcon?: () => void;
	autoComplete?: string;
	countryCode?: string;
	inputName?: string;
	maxLength?: number;
	autoFocus?: boolean;
	description?: string;
	onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
	isRequired?: boolean;
	className?: string;
	handleInputClick?: (e: any) => void;
	labelSize?: "large" | "small" 
}

/**
 * Input component props for rendering various input fields.
 * @param {string} Props.label - The label for the input field.
 * @param {'text' | 'number' | 'password' | 'date'} Props.inputType - The type of the input field.
 * @param {string} Props.placeholder - The placeholder text for the input field.
 * @param {(e: ChangeEvent<HTMLInputElement>) => void} Props.handleChange - The callback function for handling input changes.
 * @param {string | number} Props.value - The value of the input field.
 * @param {boolean} Props.isCountryCodeVisible - A flag indicating whether the country code is visible.
 * @param {(e: ICountries) => void} Props.handleChangeCountry - The callback function for handling country code changes.
 * @param {boolean} Props.isShowButton - A flag indicating whether to show a button.
 * @param {() => void} Props.handleClickBtn - The callback function for handling button clicks.
 * @param {string} Props.errorMessage - The error message to display.
 * @param {boolean} Props.isError - A flag indicating whether there is an error.
 * @param {() => void} Props.handleClearField - The callback function for clearing the input field.
 * @param {(e: KeyboardEvent<HTMLInputElement>) => void} Props.handleKeyPress - The callback function for handling key presses.
 * @param {boolean} Props.allowClear - A flag indicating whether to allow clearing the input field.
 * @param {string} Props.id - The unique identifier for the input field.
 * @param {() => void} Props.handleBlur - The callback function for handling blur events.
 * @param {() => void} Props.handleFocus - The callback function for handling focus events.
 * @param {string} Props.prefixIcon - The icon to display as a prefix.
 * @param {boolean} Props.disabled - A flag indicating whether the input field is disabled.
 * @param {string} Props.suffixIcon - The icon to display as a suffix.
 * @param {() => void} Props.handleSuffixIcon - The callback function for handling suffix icon clicks.
 * @param {string} Props.autoComplete - The value for the autocomplete attribute.
 * @param {string} Props.countryCode - The country code for phone number inputs.
 * @param {string} Props.inputName - The name attribute for the input field.
 * @param {number} Props.maxLength - The maximum length of the input field.
 * @param {boolean} Props.autoFocus - A flag indicating whether the input field should autofocus.
 * @param {string} Props.description - Additional descriptive text for the input field.
 * @param {(event: React.KeyboardEvent<HTMLInputElement>) => void} Props.onKeyDown - The callback function for handling key down events.
 * @param {boolean} Props.isRequired - A flag indicating whether the input field is required.
 * @param {string} Props.className - clasName to add additional css
 * @param {'large' | 'small'} Props.labelSize -- a flag that decides the size of the input label
 *
 */
export const Input = ({
	label,
	inputType,
	id,
	placeholder,
	handleChange,
	value,
	handleKeyPress,
	allowClear = false,
	handleClearField,
	isError = false,
	errorMessage,
	isShowButton,
	handleClickBtn,
	isCountryCodeVisible,
	handleChangeCountry,
	handleBlur = () => ({}),
	handleFocus = () => ({}),
	prefixIcon,
	disabled = false,
	suffixIcon,
	handleSuffixIcon,
	countryCode,
	autoComplete,
	inputName,
	maxLength,
	autoFocus = false,
	description,
	onKeyDown,
	isRequired = false,
	className = '',
	handleInputClick,
	labelSize = "large"
}: Props) => {
	const [maxDate, setMaxDate] = useState('');

	useEffect(() => {
		// Get the current date
		const currentDate = new Date();
		// Format the next day as 'YYYY-MM-DD'
		const formattedNextDay: any = currentDate.toISOString().split('T')[0];
		// Set the maxDate state to the formatted next day
		setMaxDate(formattedNextDay);
	}, [inputType]);

	return (
		<div className="input">
			{label && (
				<label htmlFor={id} className={`input__label--${labelSize}`}>
					{label}
					{isRequired && <span className="input__isRequired"> *</span>}
				</label>
			)}
			{description && <div className="input__description">{description}</div>}
			<div className={`input__value-container ${className}`}>
				<div className="input__group">
					<div
						className={`input__text-field input__text-field${
							isError ? '--error' : ''
						}`}
					>
						{isCountryCodeVisible && (
							<CountryCode
								handleChangeCountry={handleChangeCountry as any}
								countryCode={countryCode}
								className='phone-number__indicator-separator'
								isDisabled={disabled}
							/>
						)}
						{prefixIcon && <i className={prefixIcon} />}
						<input
							placeholder={placeholder}
							type={inputType}
							name={inputName}
							onBlur={handleBlur}
							onFocus={handleFocus}
							id={id}
							className="input__field"
							onChange={handleChange}
							value={value}
							onKeyPress={handleKeyPress}
							disabled={disabled}
							autoComplete={autoComplete}
							maxLength={maxLength}
							autoFocus={autoFocus}
							onKeyDown={onKeyDown}
							max={maxDate}
							onClick={handleInputClick}
						/>
						{allowClear && (
							<i
								className="ri-close-line input__delete"
								onClick={handleClearField}
							/>
						)}
						{suffixIcon && (
							<i
								className={`${suffixIcon} input__view`}
								onClick={handleSuffixIcon}
							/>
						)}
					</div>
					{isShowButton && (
						<Button
							label="Add"
							handleClick={handleClickBtn}
							type="button__outline button__large"
						/>
					)}
				</div>

				{isError && errorMessage && (
					<span className="input__error">{errorMessage}</span>
				)}
			</div>
		</div>
	);
};
