import { API_URL } from 'constant';
import { SelectedSessionsIdState } from 'global-stores';
import { useNetwork } from 'hooks';
import { useCallback, useState } from 'react';
import { useRecoilValue } from 'recoil';

export const useKybFilingFile = () => {
	const { post: getFileUrl, loading } = useNetwork();
	const [ fileStatus , setFileStatus ]= useState<string>("")
	const selectedIdState = useRecoilValue(SelectedSessionsIdState);

	const getFile = useCallback(async (file: any, companyId: string) => {
		const payload = {
			companyId: companyId,
			sessionId: selectedIdState[0],
			fileId: file?.uid,
		};

		const response = await getFileUrl(API_URL.GET_KYB_FILE, payload);
		setFileStatus(response?.status ?? "")
		if (response.status === 'completed' && response.data) {
			window.open(response.data, '_blank');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		getFile,
		loading,
		fileStatus
	};
};