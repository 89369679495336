import { IOption, ReactDropdown } from '@storybook';
import './event-details.scss';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState,
} from 'recoil';
import {
	EventRadioType,
	EventsState,
	SelectedEvent,
	useEvents,
} from 'views/events/store';
import { CreateEvent } from 'views/events/components/create-event';
import { EVENTURL, IEventForm } from 'views/events';
import { useNotification } from 'hooks';
import { loginState } from 'global-stores';
import { useDeepCompareEffect } from 'utils';

const defaultEventFormData = {
	name: '',
	description: '',
	address: '',
	image: '',
	date: '',
	type: { value: 'both', label: 'Biometric and QR' },
};

export const EventDetails = memo(({
	isEventCreate,
	isSubmitEventForm,
	isEventFormFilled,
	setIsEventFormFilled,
	resetEventStates,
	setIsEventLoading
}: any) => {
	const [eventOptions, setEventOptions] = useState<IOption>([]);
	const [eventForm, setEventForm] = useState<IEventForm>(defaultEventFormData);

	const [radioType, setRadioType] = useRecoilState(EventRadioType);
	const [eventsState, setEventsState] = useRecoilState(EventsState);
	const userLogin = useRecoilValue(loginState);
	const setSelectedEvent = useSetRecoilState(SelectedEvent);

	const { initEvents } = useEvents();
	const { errorNotification, successNotification } = useNotification();

	const isApiCall = useRef(false);

	useEffect(() => {
		if (isEventCreate) {
			setSelectedEvent(null);
		} else if (!isEventCreate) {
			setSelectedEvent({});
		}
	}, [isEventCreate, setSelectedEvent]);

	
	useDeepCompareEffect(() => {
		if (!eventsState.length) {
			initEvents();
		} else {
			const options = eventsState.map(el => ({ label: el.name, value: el }));
			setEventOptions(options);
			setSelectedEvent(null);
		}

	  }, [eventsState]);


	const handleSelectEvent = useCallback(
		(option: IOption) => {
			setSelectedEvent(option.value);
		},
		[setSelectedEvent]
	);

	const handleRadioBtn = useCallback(
		(type: string) => {
			if (type !== radioType) {
				if (type === 'create') {
					setSelectedEvent({});
				} else {
					setSelectedEvent(null);
				}
				setRadioType(type);
			}
		},
		[radioType, setRadioType, setSelectedEvent]
	);

	const onCreateEvent = useCallback(async () => {
		const isDuplicate = eventsState?.some(el => el.name === eventForm.name);
		if (isDuplicate) {
			errorNotification('Duplicate event name detected');
			return;
		}

		// setApiLoading(true);
		const { type, ...rest } = eventForm;
		const payload = {
			...rest,
			type: type.value,
			businessId: userLogin.business,
		};
		try {
			setIsEventLoading(true);
			const resp = await fetch(`${EVENTURL}/events`, {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const data = await resp.json();

			if (data?.message === 'ok') {
				const { data: result } = data;
				setEventsState(prev => [result, ...prev]);
				setEventForm(defaultEventFormData);
				isApiCall.current = false;
				successNotification('Event created successfully');
				resetEventStates(result)
			}
		} catch (error) {
			errorNotification('Failed to create event. Please try again.');
		} finally {
			setIsEventLoading(false)
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorNotification, eventForm, setEventsState, userLogin.business]);

	useEffect(() => {
		const isFormFilled = Object.keys(eventForm).every(
			(key: any) => eventForm[key]
		);
		setIsEventFormFilled(isFormFilled);
	}, [eventForm, setIsEventFormFilled]);

	useEffect(() => {
		const isFormFilled = Object.keys(eventForm).every(
			(key: any) => eventForm[key]
		);
		if (isSubmitEventForm && isFormFilled && !isApiCall.current) {
			isApiCall.current = true;
			onCreateEvent();
		}
	}, [eventForm, isEventFormFilled, isSubmitEventForm, onCreateEvent]);

	if (isEventCreate) {
		return (
			<CreateEvent
				eventForm={eventForm}
				setEventForm={setEventForm}
				onCreateEvent={onCreateEvent}
			/>
		);
	}

	return (
		<div className="EventDetails">
			<div
				className={`EventDetails__select-event ${
					radioType === 'select' ? 'checked' : ''
				}`}
				onClick={() => handleRadioBtn('select')}
			>
				<div className="EventDetails__select-event__radio">
					<input type="radio" checked={radioType === 'select'} />
					<div>
						<p className="EventDetails__select-event__radio__label">
							Select Event
						</p>
						<span className="EventDetails__select-event__radio__sub-label">
							Choose an event from your list to proceed.
						</span>
					</div>
				</div>
				{radioType === 'select' && (
					<ReactDropdown
						label={'Select Event'}
						placeholder="Select"
						options={eventOptions}
						handleChangeSelect={handleSelectEvent}
					/>
				)}
			</div>
			<div
				className={`EventDetails__select-event ${
					radioType === 'create' ? 'checked' : ''
				}`}
				onClick={() => handleRadioBtn('create')}
			>
				<div className="EventDetails__select-event__radio">
					<input type="radio" checked={radioType === 'create'} />
					<div>
						<p className="EventDetails__select-event__radio__label">
							Create Event
						</p>
						<span className="EventDetails__select-event__radio__sub-label">
							Create a new event.
						</span>
					</div>
				</div>
			</div>
		</div>
	);
});

EventDetails.displayName = 'EventDetails';
