import { FC } from "react"
import { CustomHandle } from "../custom-handle"
import { ICustomNode } from "../custom-node"
import { RenderEventBlock } from "./render-event-block"
import { labelData } from "views/complex-onboarding-flow/constant"

export const Event: FC<ICustomNode> = ({ id: nodeId, data }) => {
  return (
    <div>
        <RenderEventBlock label={labelData[data.label]} price={0}/>
        <CustomHandle value={nodeId}/>
    </div>
  )
}
