export const EventsColumn = [
	{ label: 'Name', key: 'name', format: 'string', width: '25%' },
	{
		label: 'Phone',
		key: 'phone',
		format: 'string',
		width: '15%',
	},
    {
		label: 'Email',
		key: 'email',
		format: 'string',
		width: '15%',
	},
	{
		label: 'Status',
		key: 'status',
		format: 'string',
		width: '15%',
	},
];

export const EVENTURL='https://api.stage.satschel.com/v2/pipelines'
