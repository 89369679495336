import { Button, Loader } from '@storybook';
import { FC, Fragment, useCallback, useMemo, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { MultidocDownloadModal } from 'components/multidoc-download-modal';
import { ESIGN_MESSAGES, PACKET_STATUS } from 'constant';
import { ISignedDocInbox } from 'views/signed-doc-inbox';
import { useSignDocHistory } from '../stores';

interface IProps {
	inbox: ISignedDocInbox;
}

export const SignDocHistoryHeader: FC<IProps> = ({ inbox }) => {
	const { _id: envelopeId, status: inboxStatus } = inbox ?? {};
	const [showDownloadModal, setShowDownloadModal] = useState(false);

	const { resendFinalSignedCopy, isLoaded } = useSignDocHistory();

	const allRecipientsCompleted = useMemo(() => {
		return inbox.recipients.every((recipient) => recipient.status === "completed");
	},[inbox.recipients])

	const fileCount = inbox?.documents?.length;
	const disabled = useMemo(() => {
		return !(
			inboxStatus === PACKET_STATUS.COMPLETED ||
			inboxStatus === PACKET_STATUS.VOIDED
		);
	}, [inboxStatus]);

	const toolTipMsg = useMemo(() => {
		if (inboxStatus === PACKET_STATUS.CONFIGURED) {			
			return allRecipientsCompleted ? ESIGN_MESSAGES.DOCUMENT_PROCESSING : ESIGN_MESSAGES.PACKET_NOT_COMPLETED;
		}
		return;
	}, [allRecipientsCompleted, inboxStatus]);

	const handleOnClick = useCallback(async () => {
		setShowDownloadModal(true);
	}, []);

	const handleResendFinalCopy = useCallback(() => {
		if(isLoaded) {
			resendFinalSignedCopy({ envelopeId, recipientEmails: [] });
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [envelopeId, isLoaded]);

	const handleClose = useCallback(() => {
		setShowDownloadModal(false);
	}, []);

	const SendDocumentLabel = useMemo(() => {
		if (!isLoaded) {
			return <Loader dimension={20} className="loader-white" type="loader" />;
		}
		return 'Resend signed copy';
	}, [isLoaded]);

	return (
		<>
			<div className="sign-doc-history-header">
				<div>Packet History</div>
				<div className="sign-doc-history-header__action-btn-wrapper">
					{inboxStatus === PACKET_STATUS.COMPLETED && (
						<Fragment>
							<div id={`history-send-signed-copy-${envelopeId}`}>
								<Button
									type="button button button__filled--primary"
									label={SendDocumentLabel}
									disabled={disabled}
									handleClick={handleResendFinalCopy}
								/>

								<ReactTooltip
									anchorId={`history-send-signed-copy-${envelopeId}`}
									place="bottom"
									content={ESIGN_MESSAGES.DOCUMENT_COPY_RESEND}
									style={{
										zIndex: 1000000,
										color: '#ffffff',
										fontSize: 12,
										width: 200,
									}}
								/>
							</div>
						</Fragment>
					)}
					<Button
						type="button button button__filled--primary"
						label={'Download'}
						disabled={disabled}
						handleClick={handleOnClick}
						id={'history.header'}
					/>
					<ReactTooltip
						anchorId={'history.header'}
						place="top"
						content={toolTipMsg}
						style={{ zIndex: 1000, color: '#ffffff', fontSize: 12, width: 200 }}
					/>
				</div>
			</div>
			{showDownloadModal && (
				// Conditional rendering of the MultidocDownloadModal component
				<MultidocDownloadModal
					visible={showDownloadModal}
					envelopeId={envelopeId}
					fileCount={fileCount}
					handleClose={handleClose}
					inboxStatus={inboxStatus}
				/>
			)}
		</>
	);
};
