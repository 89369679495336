import React from 'react';
import './date-time-picker.scss'; // Import global SCSS file

interface IDateTimePicker {
	handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	value: string;
	errorMessage?: string;
    inputName: string
    placeholder: string
	min?: string
}

export const DateTimePicker: React.FC<IDateTimePicker> = ({
	handleChange,
	value,
	errorMessage,
    inputName, 
    placeholder,
	min
}) => {
	return (
		<div className="DateTime">
			<div className="DateTime__group">
				<label htmlFor="date" className="DateTime__label">
					{placeholder}
				</label>
				<div className="DateTime__text-field">
					<input
						type="datetime-local"
						id="dateTime"
						className="DateTime__field"
						value={value}
						onChange={handleChange}
                        name={inputName}
						min={min}
					/>
				</div>
				{errorMessage && <span className="input__error">{errorMessage}</span>}
			</div>
		</div>
	);
};
