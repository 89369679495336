import 'survey-core/defaultV2.css';
import 'survey-core/survey.i18n.js';
import 'survey-creator-core/survey-creator-core.css';
import 'survey-creator-core/survey-creator-core.i18n.js';
import { SurveyCreatorComponent } from 'survey-creator-react';
import * as SurveyCore from 'survey-core';
import { inputmask } from 'surveyjs-widgets';
import * as widgets from 'surveyjs-widgets';
import { settings } from 'survey-core';
import { REACT_APP_FORM_BUILDER_KEY } from 'envs';

settings.lazyRender.enabled = true;
SurveyCore.setLicenseKey(REACT_APP_FORM_BUILDER_KEY)

inputmask(SurveyCore);
widgets.inputmask(SurveyCore);

export const SurveyBuilder = ({ creator }: any) => {
	return (
		<div id="surveyForm">
			<SurveyCreatorComponent creator={creator} />
		</div>
	);
};
