export const message = {
	InvalidEmailMessage: 'Invalid Email.',
	SomethingWentWrongMessage: 'Something went wrong.',
	CreteNewAccountMessage: `Don't have an account? Create your account, it takes less than a minute.`,
	AlreadyAccountMessage: 'Already have an account?',
	ValidPasswordMessage:
		'Password need at least one uppercase, one lowercase, one number, one special character and minimum length is 8.',
	AlreadyHaveAccount: 'Already have an account?',
	DoNotHaveAccount: `Don't have an account?`,
	LoginHeaderMessage:
		'Enter your email address and password to access admin panel.',
	WelcomeBack: 'Welcome back!',
	CREDS_SUCCESS: 'Credential added successfully!',
	DELETE_MESSAGE: 'Deleted successfully !',
	SATSCHEL: 'Satschel',
	DESCRIPTION_MESSAGE:
		'Deleting will permanently remove this file from the system.',

	BOLD_DESCRIPTION: ' This can’t be undone.',
	TITLE_MESSAGE: 'Are you sure?',
	COPY_TOASTER_MESSAGE: 'Copied !',
	COPY_TOASTER_MESSAGE_onboardingFlow: 'Copied Onboarding Flow Id !',
	COPY_TOASTER_MESSAGE_creditional_Id: 'Copied  Credential Id !',
	UPDATE_SUCCESS_MESSAGE: 'Updated Successfully!',
	VALID_NO_SPECIAL_NUMBER_MESSAGE: 'Only letters are allowed in the field.',
	VALID_NAME_MESSAGE: 'Name should be at least 3 character',
	DUPLICATE_NAME_MESSAGE: 'This name is already in use',
	VALID_PHONE_NUMBER: 'Phone numbers should consist of between 8 and 11 digits',
	VALID_NAME_INPUT_MESSAGE:
		'Name should be contain at least 3 english character',
	ADD_MOBILE_NUMBER: 'Add at least one mobile number',
	VALID_ADDRESS_MESSAGE: 'Address should be greater than 3 character',
	INVALID_COMPANY_NAME: 'No company exists with this name',
	USER_NOT_EXIST: 'User does not exists!',
	EMAIL_ALREADY_ADDED: 'This email address is already added.',
	EMAIL_NOT_VALID_MESSAGE: 'Email is not valid',
	EMAIL_REQUIRED_MESSAGE: 'Email is required',
	CAPTABLE_NOTE:
		'Please include all of the individuals information you will require in the Docusign Agreement',
	ARCHIVE_SESSION: 'Session archived successfully',
	UN_ARCHIVE_SESSION: 'Session unarchived successfully',
	ARCHIVE_DESCRIPTION:
		'Archiving this session will exclude it from current sessions list.',
	UN_ARCHIVE_DESCRIPTION:
		'Unarchiving this session will exclude it from current archived sessions list.',
	INVALID_URL_ADDRESS: 'Invalid url address',
	ONBOARDING_FLOW_NAME_REQUIRED: 'Onboarding Flow Name is required',
	// Rahul Singh: add new message
	API_RESPONSE_NOTIFICATION:
		'Your documents are being prepared for download and will be automatically downloaded in approx 30 seconds.',
	ALERT_NOTIFICATION:
		'Do you want to leave this page? You may lose unsaved changes. Click "Stay" to remain on the page or "Leave" to continue.',
	CONFIRMATION_MESSAGE: 'Do you want to Leave?',
	DOWNLOAD_ZIP_MESSAGE:
		'you will be notified via email, once the files are ready to download.',
	NAME_REQUIRED: 'Name is required',
	SUB_ACCOUNT_CREATED_SUCCESS: 'Sub-account created successfully!',
	ROLE_ADDED_SUCCESS: 'Role added successfully!',
	ROLE_UPDATED_SUCCESS: 'Role updated successfully!',
	DELETE_MESSAGE_ERROR: 'Unable to delete file!',
	FILE_SIZE_ERROR: 'File size exceeds the limit of 5MB',
	NAME_UNIQUE: 'Name must be unique',
	DELETE_CONFIRMATION_MESSAGE: 'Are you sure you want to Delete?',
	DELETE_DESCRIPTION: 'Do you still want to delete?'
};

export const signupFormMessage = {
	firstNameMessage: 'Please enter first name',
	lastNameMessage: 'Please enter last name',
	passwordMessage: 'Please enter password',
	conFirmPasswordMessage: 'Confirm your password',
	passwordNotMatchMessage: 'Password did not match',
	emailMessage: 'Please enter email',
	validEmailMessage: 'Please enter valid email',
	phoneMessage: 'Please enter phone number',
	errorMessageOnlyNumber: 'Must be only digits',
	errorMessageStringGreaterThan8: 'Must be greater than 8 digit',
	errorMessageStringLessThan14: 'Must be less than 14 digits',
};

export const MintCartaMessage = {
	ValidPhoneNumberMessage:
		'Mobile number length should be between 8 to 12 digits',
	SubmitResponseMessage: 'Thank you for the details. Its under process',
	EmptyValidCompanyNameMessage: 'Enter valid company name',
	EmptyValidFirstNameMessage: 'Enter valid first name',
	EmptyValidLastNameMessage: 'Enter valid last name',
	EmptyValidAddressMessage: 'Enter valid address',
	EmptyValidEmailMessage: 'Enter valid email',
	EmptyValidPhoneMessage: 'Enter valid phone number',
	EmptyValidLinkedinMessage: 'Enter valid linkedin profile',
	EmptyValidTickerMessage: 'Enter valid ticker',
	EmptyValidOptionVestedMessage: 'Enter valid Option vested',
};
export const MintCartaPlaceHolderMessage = {
	CompanyNameMessage: 'Enter company name',
	FirstNameMessage: 'Enter first name',
	LastNameMessage: 'Enter last name',
	AddressMessage: 'Enter address',
	EmailMessage: 'Enter email',
	PhoneMessage: 'Enter phone number',
	LinkedinMessage: 'Enter linkedin profile',
	TickerMessage: 'Enter ticker',
	OptionVestedMessage: 'Enter option vested',
};

export const preventMultipleStepIds = ['aml', 'fundInvestment', 'kyb', 'kyc', 'event'];

export const preventMultipleStepIdsMessages: { [key: string]: string } = {
	aml: 'Duplicate aml action found',
	fundInvestment: 'Duplicate fund investment action found',
	kyb: 'Duplicate KYB action found',
	kyc: 'Duplicate KYC action found',
	event: 'Duplicate Event action found',
};

export const ESIGN_MESSAGES: { [key: Uppercase<string>]: string } = {
	PACKET_NOT_COMPLETED:
		'Certificate and Documents will be available once document is completed',
	DOCUMENT_PROCESSING:
		'Once the document is prepared, it will be available for download.',
	DOCUMENT_COPY_RESEND: 
		'Resend the final copy to the recipients'	
};

export const InviteUserFromMessages: { [key: string]: string } = {
	subAccountVerification: 'Sub account verification is pending from the super admin. Kindly wait for the verification process to complete or choose another sub account.',
	roleVerification: 'You can add roles to a sub-account only if the role has an onboarding subscription. Please switch the role subscription to an onboarding subscription.',
	currentBussinessUser: "Notes : You can only view the current business user list in the session assignee. The sub-account user list is not visible in the session assignee."
};