import { FC, useEffect } from 'react';
import { useRecoilValue } from 'recoil';

import { loginState } from 'global-stores';
import { envHost } from 'helpers';
import { InjectScript } from 'utils/script';
import { REACT_APP_SESSION_WEB_COMPONENT as WEB_COMPONENT } from 'envs';
import './view-session-report.scss';

declare global {
	// eslint-disable-next-line @typescript-eslint/no-namespace
	namespace JSX {
		interface IntrinsicElements {
			'session-details': any;
		}
	}
}

interface IViewSessionRoprt {
	sessionId: string;
}

export const ViewSessionRoprt: FC<IViewSessionRoprt> = ({ sessionId }) => {
	const userDetails = useRecoilValue(loginState);

	const appendScripts = async () => {
		await InjectScript([
			{
				type: 'script',
				link: WEB_COMPONENT,
			},
		]);
	};

	useEffect(() => {
		appendScripts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="ViewSession--container">
				<div
					className="ViewSession--container--web-component"
					dangerouslySetInnerHTML={{
						__html: `<session-details
							session_id=${sessionId}
							token=${userDetails.accessToken}
							comments="true"
							env=${envHost}
							type="sessions"
							compliance_data="false"
							url="/super-admin-session-details"
							action_disable="true"
							session_type="complex"
							is_pdf="true"
							custom_loader="true"
						/>`,
					}}
				/>
		</div>
	);
};
