import { useEffect, useRef } from "react";
export function deepEqual(a: any, b: any): boolean {
    if (a === b) return true;
  
    if (typeof a !== 'object' || typeof b !== 'object' || a === null || b === null) {
      return false;
    }
  
    if (Array.isArray(a) !== Array.isArray(b)) return false;
  
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);
  
    if (keysA.length !== keysB.length) return false;
  
    for (const key of keysA) {
      if (!keysB.includes(key)) return false;
      if (!deepEqual(a[key], b[key])) return false;
    }
  
    return true;
  }

export function useDeepCompareEffect(effect: React.EffectCallback, dependencies: any[]) {
  const previousDepsRef = useRef<any[]>([]);

  useEffect(() => {
    const isSame = dependencies.every((dep, index) => {
      return deepEqual(dep, previousDepsRef.current[index]);
    });

    if (!isSame) {
      effect();
    }

    previousDepsRef.current = dependencies;
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
}




