import { useRecoilValue, useSetRecoilState } from 'recoil';
import axios from 'axios';
import { EVENTURL } from 'views/events/constants';
import { EventsState } from '../states';
import { useNotification } from 'hooks';
import { loginState } from 'global-stores';

export const useEvents = () => {
	const setEventsState = useSetRecoilState(EventsState);
	const { errorNotification } = useNotification();
	const userLogin = useRecoilValue(loginState);


	const initEvents = async () => {
		try {
			const resp = await axios(`${EVENTURL}/events?businessId=${userLogin.business}`);
			if (resp.status === 200) {
				const { data = [] } = resp.data;
				setEventsState(data?.reverse());
			}
		} catch (err) {
			errorNotification('Failed to fetch events. Please try again.');
		}
	};

	return {
		initEvents,
	};
};
