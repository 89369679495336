import { useCallback, useEffect, useMemo, useState } from 'react';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';

import { Button, Loader } from '@storybook';
import {
	CaptableGrid,
	INVESTOR_CAPTABLE_DATA,
} from '@storybook/custom-captable';
import {
	AddCapTableMapKeyAndValue,
	RequiredField,
} from '@storybook/custom-captable/states';
import Modal from '@storybook/new-modal/modal';
import { API_URL } from 'constant';
import {
	FundDetailState,
	FundInvestorFormErrorHandlerState,
	FundInvestorModalState,
	FundInvestorState,
	FundInvestors,
	FundsState,
	isFundCallingState,
} from 'global-stores';
import { envHost } from 'helpers';
import { useNetwork, useNotification } from 'hooks';
import {
	getLuckSheetData,
	isEmailValid,
	isNumber,
	isValidAmount,
	isValidCountryCode,
	isValidDate,
	isValidDecimal,
	isValidMobileNumber,
	isValidName,
	useFormatNumber,
} from 'utils';
import { FundDataForEscrowState, FundEscrow } from 'views/fund-escrow';
import {
	ADD_INVESTOR_ERROR,
	Add_FUND_MAP_CAPTABLE_ROW,
	CAPTABLE_ALL_FIELDS_REQUIRED_MESSAEG,
	Navigate,
} from 'views/funds-pages/constants';
import { ConfigCaptable } from './config-captable';
import { EditFunds } from './edit-funds';
import { JsonToCSV } from './json-to-csv';

export interface IFunds {
	name: string;
	investor: number;
	pending: string;
	moneyRaised: string;
	fundingRound: string;
	totalMoneyRaised: string;
	postMoneyValuation: string;
	id: string;
}

export const FundIconHeader = ({
	fundId,
	isUserPermissionWrite = true,
}: {
	fundId: string;
	isUserPermissionWrite?: boolean;
}) => {
	//globle states
	const funds = useRecoilValue(FundsState);
	const fundDetailList = useRecoilValue(FundDetailState);
	const setFundDataForEscrow = useSetRecoilState(FundDataForEscrowState);
	const resetList = useResetRecoilState(FundInvestorState);
	const setIsFundCalling = useSetRecoilState(isFundCallingState);
	const [fundInvestorModalState, setFundInvestorModalState] = useRecoilState(
		FundInvestorModalState
	);
	const getInvestors = useRecoilValue(FundInvestors);
	const resetFormErrorHandlerState = useResetRecoilState(
		FundInvestorFormErrorHandlerState
	);
	const selectedValue = useRecoilValue(AddCapTableMapKeyAndValue);
	const requiredField = useRecoilValue(RequiredField);

	//local states
	const [fundDataHead, setFundDataHead] = useState<IFunds>({
		name: '',
		investor: 0,
		pending: '',
		moneyRaised: '',
		fundingRound: '',
		totalMoneyRaised: '',
		postMoneyValuation: '',
		id: '',
	});
	const [isEditModal, setIsEditModal] = useState(false);
	const [isBackFromCap, setIsBackFromCap] = useState(true);

	const [investerIds, setInvesterIds] = useState<any>([]);
	const [navigate, setNavigate] = useState(Navigate.Csv);
	const [getConfigCapTable, setGetConfigCapTable] = useState<any>([]);

	// hooks
	const { errorNotification, successNotification } = useNotification();
	const { numberDecimal } = useFormatNumber();
	const { patch: addInvestor, loading: Loading } = useNetwork();

	const { isModalOpen } = fundInvestorModalState;

	const closeAddInvestorModal = () => {
		setFundInvestorModalState({
			...fundInvestorModalState,
			isModalOpen: false,
		});
		setNavigate(Navigate.Csv);
		resetList();
		setIsBackFromCap(true);
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet.exitEditMode();
		resetFormErrorHandlerState();
	};

	const openAddInvestorModal = () => {
		setFundInvestorModalState({
			...fundInvestorModalState,
			isModalOpen: true,
			id: '',
			isEdit: false,
		});
		resetList();
		resetFormErrorHandlerState();
	};

	const INVESTOR_CAPTABLE_HEADER = useMemo(
		() => fundDetailList && fundDetailList[0]?.rawData?.map(item => item.label),
		[fundDetailList]
	);

	const formateCaptableData = useCallback(() => {
		const colsData = fundDetailList
			.filter((i: any) => !i.sessionId)
			.map(item => {
				const { rawData = [] } = item ?? [];
				const formatData = rawData.map(v => v.value);
				return formatData;
			});
		const ids = fundDetailList?.map(item => {
			const { id } = item ?? [];
			return id;
		});
		return {
			colsData,
			ids,
		};
	}, [fundDetailList]);

	const openEditInvestorModal = () => {
		setIsEditModal(true);
		const { colsData = [], ids = [] } = formateCaptableData();
		setInvesterIds(ids);
		const concatInvestors = [INVESTOR_CAPTABLE_HEADER]?.concat(colsData);
		localStorage.setItem(
			'CAPTABLE_HEADER',
			JSON.stringify(concatInvestors ?? [])
		);
	};

	const onSaveCaptable = async () => {
		const { rows = [], headers = [] } = getLuckSheetData();
		const payload = {
			capTable: {
				rows,
				headers,
			},
		};
		const response = await addInvestor(`${API_URL.FUNDS}/${fundId}`, payload);
		if (response?._id && !Loading) {
			setIsFundCalling(true);
			successNotification('Added Sucessfully');
			closeAddInvestorModal();
		} else {
			errorNotification(response?.message || ADD_INVESTOR_ERROR);
		}
	};

	const handleAddInvestor = useCallback(async () => {
		if (navigate === Navigate.Csv) {
			const { expectedArray, headers, rows } = getLuckSheetData();
			if (rows.length < 1) {
				errorNotification(CAPTABLE_ALL_FIELDS_REQUIRED_MESSAEG);
				return;
			}

			const isFilled: boolean[] = [];
			let isValidData = false;
			const firstNameIndex = headers.indexOf('First Name');
			const lastNameIndex = headers.indexOf('Last Name');
			const emailIndex = headers.indexOf('Email');
			const countryCodeIndex = headers.indexOf('Country Code');
			const mobileIndex = headers.indexOf('Mobile');
			const dollarsIndex = headers.indexOf('Dollars Invested');
			const numberOfSharesIndex = headers.indexOf('No. of Shares');
			const ppsIndex = headers.indexOf('Price per Share');
			const isssueDateIndex = headers.indexOf('Issue Date');

			rows.forEach((el: any, i: number) => {
				isValidData = true;
				if (!isValidName(el[firstNameIndex])) {
					errorNotification(`Please enter a valid first name in row ${i + 1}`);
				} else if (!isValidName(el[lastNameIndex])) {
					errorNotification(`Please enter a valid last name in row ${i + 1}`);
				} else if (el[emailIndex]?.length > 0 && !isEmailValid(el[emailIndex])) {
					errorNotification(`Please enter a valid email address in row ${i + 1}`);
				} else if (el[countryCodeIndex]?.length > 0 && !isValidCountryCode(el[countryCodeIndex])) {
					errorNotification(`Please enter a valid country code in row ${i + 1}`);
				} else if (el[mobileIndex]?.length > 0 && !isValidMobileNumber(el[mobileIndex])) {
					errorNotification(`Please enter a valid mobile number in row ${i + 1}`);
				} else if (el[dollarsIndex]?.length > 0 && !isValidDecimal(el[dollarsIndex])) {
					errorNotification(`Please enter a valid Dollar Amount in row ${i + 1}`);
				} else if (el[numberOfSharesIndex]?.length > 0 && !isNumber(el[numberOfSharesIndex])) {
					errorNotification(`Please enter a valid No. of Shares in row ${i + 1}`);
				}else if (el[ppsIndex]?.length > 0 && !isValidAmount(el[ppsIndex])) {
					errorNotification(`Please enter a valid Price per Share in row ${i + 1}`);
				}else if (el[isssueDateIndex]?.length > 0 && !isValidDate(el[isssueDateIndex])) {
					errorNotification(
						`Invalid value for header Issue Date in row ${
							i + 1
						}: "value" must be in YYYY-MM-DD format`
					);
				}else {
					isValidData = false;	
				}
				isFilled.push(
					el.every(
						(fil: string | any[]) =>
							fil?.length > 0 &&
							typeof fil === 'string'
					) && el?.length === headers.length
				);
			});
			if (!isValidData){
				if (isFilled.length > 0 && !isFilled.every((el: boolean) => el)) {
					errorNotification(CAPTABLE_ALL_FIELDS_REQUIRED_MESSAEG);
					return;
				}
			}

			if (isValidData) return;

			const removeArr = ['Last Name'];
			const gridHead = INVESTOR_CAPTABLE_DATA[0]?.filter(
				(e: any) => !removeArr.includes(e)
			);

			const isSame = gridHead?.every((header: any) =>
				expectedArray[0]?.includes(header)
			);

			if (isSame) onSaveCaptable();
			else {
				const { headers = [] } = getLuckSheetData();
				const dropDownOptions = headers?.map((header: string) => ({
					label: header,
					value: header,
				}));

				const result = Add_FUND_MAP_CAPTABLE_ROW.map((item: any) => {
					const Payload = {
						options: dropDownOptions,
					};
					return { ...Payload, ...item };
				});
				setGetConfigCapTable(result);
				setNavigate(Navigate.ConfigCaptable);
			}
		} else {
			const { rows = [] } = getLuckSheetData();
			const payload = {
				capTable: {
					rows,
					headers: selectedValue,
				},
			};
			const response = await addInvestor(`${API_URL.FUNDS}/${fundId}`, payload);

			if (response?._id && !Loading) {
				setIsFundCalling(true);
				successNotification('Added Sucessfully');
				closeAddInvestorModal();
			} else {
				errorNotification(response?.message || ADD_INVESTOR_ERROR);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedValue, navigate, Loading]);

	const closeEditInvestorModal = () => {
		setIsEditModal(false);
		const finalXlsValue: any = window;
		finalXlsValue?.luckysheet.exitEditMode();
	};

	const getTotalValue = useMemo(() => {
		const value = fundDetailList?.reduce((acc: number, crr: any) => {
			const { sessionId, fullStatus = {}, invested } = crr;
			const { payIn, payOut } = fullStatus;
			const price = Number(invested ?? 0);
			if (
				(!sessionId || [payIn, payOut].includes('completed')) &&
				!isNaN(price)
			) {
				return acc + price;
			}
			return acc;
		}, 0);
		return value ?? 0.0;
	}, [fundDetailList]);

	const getPendingValue = useMemo(() => {
		const value = fundDetailList?.reduce((acc: any, crr: any) => {
			const { sessionId, fullStatus = {}, invested } = crr;
			const { payIn, payOut } = fullStatus;
			const price = Number(invested ?? 0);
			if (
				sessionId &&
				![payIn, payOut].includes('completed') &&
				!isNaN(price)
			) {
				return acc + price;
			}
			return acc;
		}, 0);
		return value ?? 0.0;
	}, [fundDetailList]);

	useEffect(() => {
		if (funds) {
			const fundData = funds.find(fund => fund.id === fundId);
			if (fundData) {
				setFundDataHead(fundData);
				setFundDataForEscrow({ ...(fundData as any) });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [funds, fundId]);

	const modalLabel = useMemo(() => {
		switch (navigate) {
			case 'Csv':
				return 'Add Investor';
			default:
				return 'Match Column Type';
		}
	}, [navigate]);

	const nextLabel = useMemo(() => {
		return Loading ? (
			<Loader type="loader" dimension={20} className="loader-white" />
		) : (
			'Save'
		);
	}, [Loading]);

	const backLabel = useMemo(() => {
		switch (navigate) {
			case Navigate.Csv:
				return 'Cancel';
			default:
				return 'Back';
		}
	}, [navigate]);

	const renderView = useMemo(() => {
		switch (navigate) {
			case Navigate.Csv:
				return (
					<div className="luckysheet-wrapper">
						<CaptableGrid isInvestor={isBackFromCap} />
					</div>
				);
			default:
				return <ConfigCaptable capTableHeader={getConfigCapTable ?? []} />;
		}
	}, [navigate, getConfigCapTable, isBackFromCap]);

	const handleBackBtn = useCallback(() => {
		const { headers, rows } = getLuckSheetData();
		const concatInvestors = [headers]?.concat(rows);
		switch (navigate) {
			case Navigate.Csv:
				return closeAddInvestorModal();
			default:
				setIsBackFromCap(false);
				localStorage.setItem(
					'CAPTABLE_HEADER',
					JSON.stringify(concatInvestors ?? [])
				);
				return setNavigate(Navigate.Csv);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate]);

	const disableNextBtn = useMemo(() => {
		const requiredList = getConfigCapTable?.filter(
			(el: { isRequired: boolean }) => el.isRequired == true
		);
		const isDisabled = requiredList?.some((item: { label: string }) =>
			requiredField.includes(item.label)
		);
		switch (navigate) {
			case Navigate.Csv:
				return false;
			default:
				return isDisabled;
		}
	}, [navigate, getConfigCapTable, requiredField]);

	const renderViewModal = useMemo(() => {
		return (
			<>
				<div className="user-roles--header user-role-add-investor-header">
					<span>{modalLabel}</span>
					<div
							className="Modal__closeButton"
							onClick={closeAddInvestorModal}
							role="button"
							tabIndex={0}
						>
							<i className="ri-close-line Modal__closeIcon" />
						</div>

				</div>
				{renderView}
				<div className="ConfigureModal--footer">
					<Button
						label={backLabel}
						handleClick={handleBackBtn}
						type="button__filled button__filled--secondary"
					/>
					<Button
						label={nextLabel}
						handleClick={handleAddInvestor}
						type="button__filled button__filled--primary"
						disabled={(disableNextBtn as boolean) || Loading}
					/>
				</div>
			</>
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalLabel, nextLabel, renderView, Loading, disableNextBtn, backLabel]);

	return (
		<div className="fund-detail-summary">
			<div className="header__name">{fundDataHead.name}</div>
			<div className="fund-detail--summary">
				<div className="Outer_funds_details">
					<div className="count_outer">
						<i className="ri-group-fill"></i>
						<div className="count_container">
							<div className="count_value">{getInvestors.investors}</div>
							<span className="count_label">Investors</span>
						</div>
					</div>
					<div className="count_outer">
						<i className="ri-group-fill money-pending"></i>
						<div className="count_container">
							<div className="count_value">{getInvestors.pendingInvestors}</div>
							<span className="count_label">Pending Investors</span>
						</div>
					</div>
					<div className="count_outer">
						<i className="ri-hand-coin-fill"></i>
						<div className="count_container">
							<div className="count_value">
								${getTotalValue?.toFixed(2) ?? 0.0}
							</div>
							<span className="count_label">Total Money Raised (USD)</span>
						</div>
					</div>
					<div className="count_outer">
						<i className="ri-hand-coin-fill money-pending"></i>
						<div className="count_container">
							<div className="count_value">
								${getPendingValue?.toFixed(2) ?? 0.0}
							</div>
							<span className="count_label">Money Raised Pending (USD)</span>
						</div>
					</div>
					<div className="count_outer">
						<i className="ri-funds-box-fill"></i>
						<div className="count_container">
							<div className="count_value">
								${numberDecimal(Number(fundDataHead.postMoneyValuation) ?? 0)}
							</div>
							<span className="count_label">Post Money Valuation (USD)</span>
						</div>
					</div>
				</div>

				<>
					<Modal
						isOpen={isModalOpen}
						closeModal={closeAddInvestorModal}
						className={`react-modal_IFunds_Add_Investor ${
							navigate === Navigate.ConfigCaptable ? 'override_modal' : null
						}`}
						modalName="Session modal"
						showHeader={false}
					>
						{renderViewModal}
					</Modal>
				</>
				<EditFunds
					isModalOpen={isEditModal}
					closeAddInvestorModal={closeEditInvestorModal}
					investerIds={investerIds}
					fundId={fundId}
				/>
				<div className="funds-action-btn-wrapper">
					{fundDetailList?.length > 0 && (
						<Button
							label="Edit Investor"
							handleClick={openEditInvestorModal}
							type="button__filled button__filled--primary fundIconHeader__editInvestor_button_hide"
							disabled={envHost !== 'stage'}
						/>
					)}
					{isUserPermissionWrite && (
						<Button
							label="Add Investor"
							handleClick={openAddInvestorModal}
							type="button__filled button__filled--primary"
						/>
					)}
					{envHost === 'stage' && <FundEscrow />}
					<JsonToCSV />
				</div>
			</div>
		</div>
	);
};
