import { atom } from 'recoil';
import { IAction } from 'global-stores';

type INavigate = 'deliveryMethod' | 'publish' | 'complex-flow' | 'selectSteps';

export const OnboardingFooterNavigateState = atom<INavigate>({
	key: 'onboarding-footer-navigate-state',
	default: 'complex-flow',
});

export const SelectedStepsState = atom<any[]>({
	key: 'selected-steps-state',
	default: [],
});

export const AddedActionsState = atom<any[]>({
	key: 'added-actions-state',
	default: [],
});

export const SelectedRadioState = atom<string>({
	key: 'radio-added-actions-state',
	default: 'payIn',
});

export const AddedFileDataState = atom<{
	configured: boolean;
	_id: string;
	name: string;
	size: string;
	createdAt: string;
	type: 'generic' | 'customUpload';
}>({
	key: 'added-file-data-state',
	default: {
		configured: false,
		_id: '',
		name: '',
		size: '',
		createdAt: '',
		type: 'generic',
	},
});

export const UnConfiguredStep = atom<any[]>({
	key: 'un-configured-step',
	default: ['defualt'],
});

export const ConfiguredStepState = atom<any[]>({
	key: 'configured-step-state',
	default: [],
});

export const ConfigureSurvayFormState = atom<any | null>({
	key: 'configured-form-state',
	default: null,
});

export const IsConfigurationChecking = atom<boolean>({
	key: 'is-configuration-checking',
	default: false,
});

export const SelectedCheckboxStepsState = atom<
	{ [key: string]: boolean } | any
>({
	key: 'selected-checkbox-step-state',
	default: {
		accreditation: true,
		aml: true,
		fundInvestment: true,
		kyc: true,
		signAgreement: true,
		authentication: true,
		form: true,
		kyb: true,
		successScreen: true,
		event: true,
		//TODO : @avinash
		proofReading: true,
	},
});

export const CurrentStepState = atom<IAction>({
	key: 'selected-step-state',
	default: {
		label: '',
		description: '',
		key: '',
		price: 0,
		metadata: [],
		_id: '',
	},
});

export const SettingFormInitState = atom<{
	[key: string]: boolean | string | any;
}>({
	key: 'setting-form-init-state',
	default: {},
});

export const CustomAccreditationTempId = atom<string>({
	key: 'custom-accreditation-temp-id-state',
	default: '',
});
