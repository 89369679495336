import { useCallback, useEffect, useMemo, useState } from 'react';
import { Header } from 'views/header';
import Modal from '@storybook/new-modal/modal';
import { useInterval, useNotification } from 'hooks';
import { EventsList } from './components';
import { EventsState, useEvents } from './store';
import './events.scss';
import { CreateEvent } from './components/create-event';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Button, Loader } from '@storybook';
import { loginState } from 'global-stores';
import { EVENTURL } from './constants';

export type IEventForm = {
	name: string;
	description: string;
	address: string;
	image: string;
	date: string;
	businessId?: string;
	type: { label: string; value: string };
	[key: string]: any;
};

const defaultEventFormData = {
	name: '',
	description: '',
	address: '',
	image: '',
	date: '',
	type: { value: 'both', label: 'Biometric and QR' },
}

export const Events = () => {
	const [isShowEventFormModal, setShowEventFormModal] = useState(false);
	const [eventForm, setEventForm] = useState<IEventForm>(defaultEventFormData);
	const [isLoading, setLoading] = useState(false);
	const [apiLoading, setApiLoading] = useState(false);

	const [eventsState, setEventsState] = useRecoilState(EventsState);
	const userLogin = useRecoilValue(loginState);

	const { initEvents } = useEvents();
	const { errorNotification, successNotification } = useNotification();

	const handleCreateEvent = () => {
		setShowEventFormModal(true);
	};

	const init = async () => {
		setLoading(true);
		await initEvents();
		setLoading(false);
	};

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleClose = useCallback(() => {
		setShowEventFormModal(false);
		setEventForm(defaultEventFormData);
	}, []);

	useInterval(() => {
		initEvents();
	}, 3000);

	const onCreateEvent = useCallback(async () => {
		const isDuplicate = eventsState?.some(el => el.name === eventForm.name);
		if (isDuplicate) {
			errorNotification('Duplicate event name detected');
			return;
		}

		setApiLoading(true);
		const { type,...rest } = eventForm;
		const payload= {
			...rest,
			type:type.value,
			businessId: userLogin.business,
		};
		try {
			const resp = await fetch(`${EVENTURL}/events`, {
				method: 'POST',
				body: JSON.stringify(payload),
				headers: {
					'Content-Type': 'application/json',
				},
			});
			const data = await resp.json();

				if (data?.message === 'ok') {
					const { data: result } = data;
					setEventsState(prev => [result, ...prev]);
					successNotification('Event created successfully');
					handleClose();
				}
			} catch (error) {
				errorNotification('Failed to create event. Please try again.');
			} finally {
				setApiLoading(false);
			}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorNotification, eventForm, setEventsState, userLogin.business]);

	const btnDisable = useMemo(() => {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const {type,...rest}=eventForm
		const isValid = Object.values(rest).every(v => v?.length);
		return isValid;
	}, [eventForm]);

	return (
		<div className="Events">
			<Header
				title="Events"
				showBtn
				btnLabel="Create New"
				handleClickBtn={handleCreateEvent}
			/>
			<div className="Events--list">
				<EventsList apiLoading={isLoading} />
			</div>

			{isShowEventFormModal && (
				<Modal
					isOpen={isShowEventFormModal}
					modalName="Events"
					closeModal={handleClose}
					className="Events-Modal"
					showCloseBtn
					isStopOutsideClick={false}
					optionalClassName="Events--Modal-body"
				>
					<div className="Events--header">
						<h2>Create New Event</h2>
					</div>
					<CreateEvent eventForm={eventForm} setEventForm={setEventForm} onCreateEvent={onCreateEvent}/>
					<div className="Events--footer">
						<Button
							label="Cancel"
							type="button__filled button__filled--secondary"
							handleClick={handleClose}
						/>
						<Button
							label={
								!apiLoading ? 'Create' : <Loader dimension={20} type="loader" />
							}
							type="button__filled button__filled--primary"
							handleClick={onCreateEvent}
							disabled={!btnDisable || apiLoading}
						/>
					</div>
				</Modal>
			)}
		</div>
	);
};
