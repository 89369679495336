import Modal from '@storybook/new-modal/modal';
import { SessionTreeGraph } from 'views/complex-onboarding-flow/components';
import { advancedDescriptionModal } from 'views/pipelines/constants';

type Props = {
	isOpen: boolean;
	closeModal: () => void;
	details: any;
	isOnboardingList?: boolean;
};

export const ComplexStepsView = ({
	closeModal,
	isOpen,
	details,
	isOnboardingList = false,
}: Props) => {
	return (
		<Modal
			isOpen={isOpen}
			modalName="Conditional Onboarding Flow Details"
			closeModal={closeModal}
			className="conditional-onboarding-details"
			showCloseBtn={true}
			isStopOutsideClick
			title={
				// Gaurav: Changed title and description according to new design.
				<div className="conditional-onboarding-details__title-wrapper">
					<div className="conditional-onboarding-details__title">
						Onboarding flow overview
					</div>
					<div className="conditional-onboarding-details__sub-title">
						{isOnboardingList
							? advancedDescriptionModal.onboarding
							: advancedDescriptionModal.session}
					</div>
				</div>
			}
		>
			<div className="conditional-flow-steps ObFlowConfigureModal__stepper-container">
				<SessionTreeGraph details={details} isSessionModal />
			</div>
		</Modal>
	);
};
