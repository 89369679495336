import { FC, memo, useMemo } from 'react';

import { QuestionnaireQuestion } from '../questionnaire-question';
import { AllowedQuestionnaire } from '../questionnaire-choice';
import { areEqual, FixedSizeList as List } from 'react-window';
import './questionnaire-template.scss';

interface IQuestionnaireTemplate {
	id: string;
	selectedForm: string;
	existFormData: any;
}

let ids: any = '';
let form: any = null;

const Row = memo(({ data, style, index }: any) => {
	const element = data[index];

	return (
		<div
			style={{
				...style,
				display: 'flex',
				flexDirection: 'column',
				minWidth: 'max-content',
				pointerEvents: 'all',
			}}
		>
			<QuestionnaireQuestion
				key={element?.name}
				element={element}
				id={ids}
				page={element?.page ?? ''}
				selectedForm={form}
			/>
		</div>
	);
}, areEqual);

Row.displayName = 'Row';

const QuestionnaireWindowView: FC<IQuestionnaireTemplate> = ({
	existFormData,
	selectedForm,
	id,
}) => {
	ids = id;
	form = selectedForm;
	const { pages } = existFormData ?? {};

	const values = useMemo(() => {
		const clonedPages = JSON.parse(JSON.stringify(pages));
		clonedPages?.forEach((page: any) => {
			page.elements?.forEach((element: any) => {
				element.pageName = page.name;
			});
		});
		const fatData = (clonedPages ?? [])
			.map((page: any) =>
				(page?.elements ?? []).filter((element: any) => {
					if (AllowedQuestionnaire[element?.type]) {
						return { ...element, page: page?.name };
					}
				})
			)
			.reduce((flat: [], current: []) => {
				return flat.concat(current);
			}, []);
		return fatData;
	}, [pages]);

	return (
		<div className="QuestionnaireTemplate">
			{selectedForm && (
				<div className="QuestionnaireTemplate--header">
					<div className="QuestionnaireTemplate--title">{selectedForm}</div>
					<div className="QuestionnaireTemplate--Edit"></div>
				</div>
			)}

			<div className="QuestionnaireTemplate--window-questions nowheel">
				<List
					className="list"
					height={400}
					itemCount={values.length}
					itemData={values}
					itemSize={250}
					direction="vertical"
					width={'100%'}
				>
					{Row}
				</List>
			</div>
		</div>
	);
};

export const QuestionnaireTemplate: FC<IQuestionnaireTemplate> = props => {
	return <QuestionnaireWindowView {...props} />;
};
