import { IEdge_, INode_, IComplexNode } from '../type';
import { useRef, useState } from 'react';
import {
	MarkerType,
	addEdge,
	applyEdgeChanges,
	applyNodeChanges,
	getConnectedEdges,
	getIncomers,
	getOutgoers,
} from 'reactflow';
import {
	useRecoilState,
	useRecoilValue,
	useResetRecoilState,
	useSetRecoilState,
} from 'recoil';
import { v4 } from 'uuid';

import {
	preventMultipleStepIds,
	preventMultipleStepIdsMessages,
} from 'constant';
import {
	EmailMessageReminderValue,
	IPipelineSteps,
	PipelineMasterSteps,
	PipelineSettingsFormState,
	SelectedMultipleDateState,
	SelectedOnboardingAction,
	isChecqueFraudSelected,
} from 'global-stores';
import { isStage } from 'helpers';
import { useNotification } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'routes';
import { isCurrentMonthOrNextMonthExist } from 'utils';
import {
	AddedActionsState,
	OnboardingFooterNavigateState,
	SelectedCheckboxStepsState,
	SelectedRadioState,
	useOnboardingHook,
} from 'views/onboarding-flow/store';
import {
	ComplexSettingFormState,
	ReminderDeadLineState,
	SendNotificationState,
	UnitPriceInputValueState,
	UnitPriceToggleState,
} from 'views/pipeline';
import { PathOptions, StatusOptions } from '../components';
import {
	ActionList,
	ConfigurableSteps,
	RequiredValidationKeysForNodes,
	StageConfigurableSteps,
	SuccessNode,
	kybObjectStep,
	labelData,
} from '../constant';
import {
	ComplexDynamicFormPathsState,
	ComplexDynamicFormStatusState,
	ComplexFlowUnconfigedState,
	EdgesState,
	NodesState,
} from '../stores/states';
import { proofReadingKey } from 'views/pipelines';

let id = v4();

const getId = () => {
	const reactFlowId = `node-${v4()}`;
	id = reactFlowId;
	return reactFlowId;
};

export const useComplexLayout = () => {
	const navigate = useNavigate();
	const [nodes, setNodes] = useRecoilState(NodesState);
	const [edges, setEdges] = useRecoilState(EdgesState);
	const setComplexFlowConfiged = useSetRecoilState(ComplexFlowUnconfigedState);
	const reactFlowWrapper = useRef(null);
	const [reactFlowInstance, setReactFlowInstance] = useState<any>(null);
	const setAddedActions = useSetRecoilState(AddedActionsState);
	const [steps, setSteps] = useRecoilState(PipelineMasterSteps);
	const selectedCheckbox = useRecoilValue(SelectedCheckboxStepsState);
	const [complexSettingForm, setComplexFlowSetting] = useRecoilState(
		ComplexSettingFormState
	);

	const resetSelectedRadioLabel = useResetRecoilState(SelectedRadioState);
	const setNavigate = useSetRecoilState(OnboardingFooterNavigateState);
	const setPipelineSettingFormState = useSetRecoilState(
		PipelineSettingsFormState
	);
	const isChequeFraud = useRecoilValue(isChecqueFraudSelected);

	const setSelectedOnboarding = useSetRecoilState(SelectedOnboardingAction);
	const setSelectedMultipleDate = useSetRecoilState(SelectedMultipleDateState);
	const setEmailMessageReminder = useSetRecoilState(EmailMessageReminderValue);
	const setReminderDays = useSetRecoilState(SendNotificationState);
	const setDynamicFormPaths = useSetRecoilState(ComplexDynamicFormPathsState);
	const setDynamicFormStatus = useSetRecoilState(ComplexDynamicFormStatusState);
	const { onboardingNameWithTime } = useOnboardingHook();
	const setUnitPriceInputValue = useSetRecoilState(UnitPriceInputValueState);
	const setIsUnitPriceToggleOn = useSetRecoilState(UnitPriceToggleState);
	const setDeadline = useSetRecoilState(ReminderDeadLineState);

	//constants
	const { proofVerification } = proofReadingKey;

	const { errorNotification, warningNotification } = useNotification();

	const { PIPELINE, FLOW } = ROUTES;

	const onNodesChange = (changes: any) => {
		setNodes((prev: any) => {
			const prevState = JSON.parse(JSON.stringify(prev));
			return applyNodeChanges(changes, prevState);
		});
	};

	const onEdgesChange = (changes: any) => {
		setEdges((eds: any) => applyEdgeChanges(changes, eds));
	};

	const handleDeleteEdges = (id: string) => {
		const selectedEdges = edges.find((el: any) => el.id === id);
		setNodes((prev: any) => {
			const newNodes = structuredClone(prev);
			return newNodes.map((el: any) => {
				if (el.id === selectedEdges.target) {
					const indexToDelete = el?.edgeId?.indexOf(selectedEdges.id);
					el.parentId = el.parentId.filter(
						(parentEl: string, index: number) =>
							(parentEl !== selectedEdges.source && index !== indexToDelete) ||
							(parentEl === selectedEdges.source && index !== indexToDelete)
					);
					el.edgeId = el?.edgeId?.filter(
						(edgeEL: string) => edgeEL !== selectedEdges.id
					);
				}
				if (el.stepId === 'dynamicForm') {
					const conditionIndex = el.conditions?.findIndex(
						(condition: { key: string }) =>
							condition.key === selectedEdges.sourceHandle
					);
					if (conditionIndex !== -1) {
						delete el.conditions[conditionIndex].target;
						delete el.conditions[conditionIndex].nodeId;
					}
				}
				return el;
			});
		});

		setEdges((prev: any) => {
			const newEdges = structuredClone(prev);
			return newEdges.filter((el: any) => el.id !== id);
		});
	};

	const getConnections = (sourceName: string) => {
		const connections = [];
		let currentSource = sourceName;
		// eslint-disable-next-line no-constant-condition
		while (true) {
			const edge = edges.find((e: any) => e.source === currentSource);
			if (!edge) {
				break;
			}
			connections.push(edge.source, edge.target);
			currentSource = edge.target;
		}

		return connections;
	};

	/**
	 * Created by @avinashSatschel
	 * Retrieves an array of step IDs for all descendants of a given parent ID in a tree-like structure.
	 * @param {any[]} objects - An array of objects representing nodes in the tree.
	 * @param {string} parentId - The ID of the parent node for which to retrieve descendant step IDs.
	 * @returns {string[]} - An array of step IDs for all descendants of the specified parent ID.
	 */
	const getChildrenStepIds = (objects: any, parentId: string): string[] => {
		// Initialize a stack with the root parent ID.
		const stack = [parentId];
		// Initialize an array to store the collected step IDs.
		const stepIds = [];
		// Iterate through the stack to process each level of descendants.
		while (stack.length > 0) {
			// Pop the current node ID from the stack.
			const currentId = stack.pop();
			// Filter objects to find children of the current node.
			const children = objects.filter(
				(obj: any) => obj.parentId && obj.parentId.includes(currentId)
			);
			// Process each child, collecting their step IDs and updating the stack.
			for (const child of children) {
				// Collect the step ID of the child.
				stepIds.push(child.stepId);
				// Add the child's ID to the stack for further exploration.
				stack.push(child.id);
			}
		}

		// Return the array of collected step IDs.
		return stepIds;
	};

	const getAllStepIdsForParent = (id: any, data: any) => {
		const stepIds: any[] = [];
		function findParentStepIds(currentId: any) {
			const node = data?.find((item: { id: any }) => item.id === currentId);

			if (node) {
				stepIds.push(node.stepId);
				if (Array.isArray(node.parentId)) {
					node.parentId.forEach((parentId: any) => {
						findParentStepIds(parentId);
					});
				}
			}
		}

		findParentStepIds(id);
		return stepIds;
	};

	const addExtraConditionByStepId = (connection: any, source: any) => {
		if (source.stepId === 'dynamicForm') {
			const payload = {
				key: connection.sourceHandle,
				target: connection.target,
			};
			return payload;
		}
		if (/form/.test(source.stepId)) {
			const [nodeId, labelId, key] = connection.sourceHandle.split('_');

			const payload = {
				currentId: nodeId,
				labelId,
				label: key,
				key,
			};

			return payload;
		}
		return undefined;
	};

	const findDuplicateNodes = (nodes: Array<string>) => {
		const duplicates = nodes.filter(
			(item, index) =>
				nodes.indexOf(item) !== index && preventMultipleStepIds.includes(item)
		);

		return Array.from(new Set(duplicates));
	};

	/**
	 * Checks if the conditions for showing an error notification are met.
	 *
	 * @param {any} connection - The connection object containing source and sourceHandle.
	 * @param {INode_} soureNode - The source node to check against conditions.
	 * @param {INode_} targetNode - The target node to check against conditions.
	 * @param {string[]} childrenStepIds - Array of child step IDs.
	 * @param {IEdge_[]} edges - The array of edges to find parent edges.
	 * @returns {boolean} - Returns true if an error notification should be shown, otherwise false.
	 */
	const showErrorOnConnectSuccessScreen = (
		connection: { source: string; sourceHandle: string },
		soureNode: INode_,
		targetNode: INode_,
		childrenStepIds: string[],
		edges: IEdge_[]
	): boolean => {
		// Function to find all parent edges of a given target node
		const findParentEdges = (targetNode: string, edges: IEdge_[]) => {
			const parentEdges = edges.filter(edge => edge.target === targetNode);
			let allParents = [...parentEdges];

			parentEdges.forEach(edge => {
				const parentsOfParent = findParentEdges(edge.source, edges);
				allParents = [...allParents, ...parentsOfParent];
			});

			return allParents;
		};

		// Function to filter edges based on specified conditions
		const filterEdges = (edges: IEdge_[]) =>
			edges.filter(
				edge =>
					edge.source !== edge.sourceHandle &&
					!edge.sourceHandle.includes(SuccessNode.FORM_INDIVIDUAL) &&
					!edge.sourceHandle.includes(SuccessNode.FORM_BUSINESS)
			);

		// Find and filter parent edges
		const allParentEdges = findParentEdges(connection.source, edges);
		const filteredParentEdges = filterEdges(allParentEdges);

		// Check if the target node is a success step or if any of its children are success steps
		const isSuccessStep =
			targetNode.stepId === SuccessNode.STEP_ID ||
			childrenStepIds.includes(SuccessNode.STEP_ID);

		// Check if the source node is not a form step
		const isSourceNotFormStep = soureNode.stepId !== SuccessNode.FORM_ID;

		// Check if the connection source and source handle are different
		const isSourceHandleDifferent =
			connection.source !== connection.sourceHandle;

		// Check if there are any parent edges after filtering
		const hasFilteredParentEdges = filteredParentEdges.length > 0;

		// Return the appropriate boolean value based on the conditions
		return (
			isSuccessStep &&
			(isSourceNotFormStep
				? isSourceHandleDifferent || hasFilteredParentEdges
				: hasFilteredParentEdges)
		);
	};

	const onConnect = (connection: any) => {
		//Retriving all stepIds from children
		const childrenStepIds = getChildrenStepIds(nodes, connection.target);

		const payload = {
			...connection,
			//Avinash: added type for custom adges
			type: 'buttonedge',
			markerEnd: {
				type: MarkerType.Arrow,
				width: 10,
				height: 10,
				color: '#b3b3b3',
			},
			style: {
				strokeWidth: 3,
				stroke: '#b3b3b3',
				zIndex: 999,
			},
		};

		const isChildrenConnection = getConnections(connection.target)?.includes(
			connection.source
		);

		const alreadyConnected = edges.find(
			(el: any) =>
				el.source === connection.source &&
				el.sourceHandle === connection.sourceHandle
		);

		if (connection.source === connection.target) {
			errorNotification('can not connect to self');
			return;
		}

		const soureNode = nodes.find((el: any) => el.id === connection.source);
		const targetNode = nodes.find((el: any) => el.id === connection.target);
		//Added preventMultipleStepIds.includes(soureNode.stepId) because isChildreanExist is checking in all nodes.
		const isChildrenExist =
			childrenStepIds.includes(soureNode.stepId) &&
			preventMultipleStepIds.includes(soureNode.stepId);
		const parentStepIds = getAllStepIdsForParent(connection.source, nodes);
		// Check if the target node's step ID is in the list of prevented step IDs
		// and either the target node's step ID is in the list of parent step IDs or the source node has children with the same step ID.
		// Shahbaaz: update the logic due to soureNode.setepId geeting previous one
		const isPreventMultipleStepIds =
			preventMultipleStepIds.includes(targetNode.stepId) &&
			(parentStepIds.includes(targetNode.stepId) || isChildrenExist);
		const duplicateNodes = findDuplicateNodes([
			...childrenStepIds,
			...parentStepIds,
		]);

		if (duplicateNodes?.length) {
			errorNotification(
				(duplicateNodes?.length > 1
					? 'Duplicate Actions are Found: '
					: 'Duplicate Action is Found: ') + duplicateNodes
			);
			return;
		}
		if (isPreventMultipleStepIds) {
			errorNotification(
				preventMultipleStepIdsMessages[
					isChildrenExist ? soureNode.stepId : targetNode.stepId
				] ?? 'Integration conflict detected in current flow.'
			);
			//return from the function
			return;
		}
		if (targetNode.stepId === 'aml' || soureNode.stepId === 'aml') {
			const data = parentStepIds.includes('kyc');
			if (!data) {
				errorNotification('Configuring AML before KYC is not allowed.');
				return;
			}
		}

		if (targetNode.stepId === 'kybForm' && soureNode.stepId !== 'kyb') {
			errorNotification('Kyb form can only be connected to Kyb step ');
			return;
		}

		if (alreadyConnected) {
			errorNotification('This is already connected ');
			return;
		}

		// Usage of the showErrorOnConnectSuccessScreen function
		if (
			showErrorOnConnectSuccessScreen(
				connection, // The current connection object
				soureNode, // The source node being evaluated
				targetNode, // The target node being evaluated
				childrenStepIds, // List of child step IDs for comparison
				edges // The list of edges to evaluate parent relationships
			)
		) {
			errorNotification(SuccessNode.ERROR_MESSAGE); // Show error notification if conditions are met
			return; // Exit the function early if an error notification is shown
		}

		if (!isChildrenConnection) {
			const sourceNode = JSON.parse(
				JSON.stringify(nodes.find((el: any) => el.id === connection.source))
			);

			const prevNodesValue: any = JSON.parse(JSON.stringify(nodes));
			const index = prevNodesValue.findIndex(
				(el: any) => el.id === connection.target
			);
			prevNodesValue[index].parentId.push(sourceNode.id);
			setNodes((prev: any) => {
				const prevObj = JSON.parse(JSON.stringify(prev));
				const targetIndex = prevObj.findIndex(
					(el: any) => el.id === connection.target
				);
				const sourceIndex = prevObj.findIndex(
					(el: any) => el.id === connection.source
				);

				if (targetIndex !== -1) {
					prevObj[targetIndex].edgeId = [];
					const eCondition = addExtraConditionByStepId(connection, sourceNode);
					//adding condition for form Step only
					if (eCondition) {
						if (sourceNode.stepId === 'dynamicForm') {
							const handlerIndex = prevObj[sourceIndex]['conditions'].findIndex(
								(condition: { [key: string]: string }) =>
									condition.key === eCondition.key
							);
							if (handlerIndex !== -1) {
								const prevCondition = JSON.parse(
									JSON.stringify(
										prevObj[sourceIndex]['conditions'][handlerIndex]
									)
								);
								prevObj[sourceIndex]['conditions'][handlerIndex] = {
									...prevCondition,
									target: (eCondition as { [key: string]: string }).target,
								};
							}
						} else {
							prevObj[sourceIndex]['conditions'].push(eCondition);
						}
						prevObj[targetIndex]['sourceHandle'] = eCondition.key;
					}
					//no need to set sourceHandle for form here as we are setting in eCondition block above
					if (!/dynamicForm|form/.test(prevObj[sourceIndex].stepId)) {
						prevObj[targetIndex]['sourceHandle'] = connection.sourceHandle;
					}

					prevObj[targetIndex].parentId.push(sourceNode.id);

					const newEdge = addEdge(payload, edges);

					newEdge.forEach((el: any) => {
						if (el.target === prevObj[targetIndex].id) {
							prevObj[targetIndex].edgeId.push(el.id);
						}
					});

					if (sourceIndex !== -1) {
						for (let i = 0; i < prevObj[sourceIndex].conditions.length; i++) {
							const connectedId = prevObj.find(
								(el: any) =>
									el.sourceHandle == prevObj[sourceIndex].conditions[i].key &&
									el.parentId.includes(prevObj[sourceIndex].id)
							)?.id;

							prevObj[sourceIndex].conditions[i]['nodeId'] = connectedId;
						}
					}
				}
				return prevObj;
			});

			setEdges((eds: any) => addEdge(payload, eds));
		} else {
			errorNotification('Can not connect to parent node');
		}
	};

	const renderType = (type: string) => {
		switch (type) {
			case 'formAction':
				return 'dynamicForm';
			case 'accountForm':
				return 'form';
			case 'eventVerification':
					return 'event';
			default:
				return type;
		}
	};

	const onDrop = (event: any, cb: any) => {
		event.preventDefault();
		/* 
		 @avinashSatschel
         If the 'isChequeFraud' flag is set to true, prevent further execution of the code block.
         This condition ensures that additional actions are not added when Cheque Fraud is enabled,
         as Cheque Fraud handling may require a specific flow without additional steps.
        */
		if (isChequeFraud) {
			warningNotification(
				'Check Defense is ON, no actions can be added to the flow.'
			);
			// Exit the function to prevent further processing when Cheque Fraud is enabled.
			return;
		}
		const reactFlowBounds = (
			reactFlowWrapper as any
		).current.getBoundingClientRect();
		const type = event.dataTransfer.getData('application/complex-flow');
		if (typeof type === 'undefined' || !type) {
			return;
		}
		const position = reactFlowInstance.project({
			x: event.clientX - reactFlowBounds.left,
			y: event.clientY - reactFlowBounds.top,
		});

		const newNode = {
			id: getId(),
			type: 'customNode',
			position,
			key: renderType(type),
			conditions: [],
			updateStyle: true,
			stepId: ActionList[type],
			label: labelData[type],
			parentId: [],
			data: {
				label: `${type}`,
			},

			zIndex: 99999,
		};
		setNodes((nds: any) => nds.concat(newNode));

		if (
			(isStage ? StageConfigurableSteps : ConfigurableSteps).includes(
				ActionList[type]
			)
		) {
			setComplexFlowConfiged((prev: any) => {
				const newArr = structuredClone(prev);
				newArr.push({
					id: newNode.id,
					configed: false,
					stepId: ActionList[type],
				});
				return newArr;
			});
		}

		const payload = {
			draggableId: type,
			type: 'DEFAULT',
			source: {
				index: id,
				droppableId: 'action-list',
			},
			reason: 'DROP',
			mode: 'FLUID',
			destination: {
				droppableId: 'added-action-list',
				index: 0,
			},
			combine: null,
			nodeId: newNode.id,
		};
		cb?.(payload);
	};

	const onDragOver = (event: any) => {
		event.preventDefault();
		event.dataTransfer.dropEffect = 'move';
	};

	const onNodesDelete = (deleted: any) => {
		setEdges(
			deleted.reduce((acc: any, node: any) => {
				const incomers = getIncomers(node, nodes, edges);
				const outgoers: any = getOutgoers(node, nodes, edges);
				const connectedEdges = getConnectedEdges([node], edges);

				const remainingEdges = acc.filter(
					(edge: any) => !connectedEdges.includes(edge)
				);

				const createdEdges = incomers.flatMap(({ id: source }) =>
					outgoers.map(({ id: target }: any) => ({
						id: `${source}->${target}`,
						source,
						target,
					}))
				);

				return [...remainingEdges, ...createdEdges];
			}, edges)
		);
	};

	const handleSettingFormState = (data: any) => {
		const newState: { [key: string]: boolean | string | undefined } = {};
		data?.forEach((step: IPipelineSteps) => {
			step.actions?.forEach((action: any) => {
				action.metadata?.forEach((meta: any) => {
					const { key } = meta;
					if (meta.default === 'true') {
						return (newState[key] = true);
					}
					if (meta.default === 'false') {
						return (newState[key] = false);
					}

					if (key === 'accreditationType' || key === 'accreditationDocument') {
						return (newState[key] = '');
					}
					if (
						key === 'reminderSettingEmailMessage' ||
						key === 'reminderSettingTextMessage'
					) {
						const value = meta?.default?.replace(
							' to recieve your {shares}',
							''
						);
						return (newState[key] = value ?? '');
					}
					return (newState[key] = meta.default);
				});
			});
		});

		const payload = { ...newState, isModifyOrClone: true };

		setPipelineSettingFormState(prev => ({
			...prev,
			...payload,
		}));
	};

	const handleSteps = (data: any, arr: any) => {
		const newArr = JSON.parse(JSON.stringify(arr));

		for (const key in data) {
			const findIndex = newArr.findIndex(
				(el: { key: string }) => el.key === key
			);

			for (let i = 0; i < data[key]?.actions?.length; i++) {
				const actionKey = data[key].actions[i].actionId;
				const actionIndexKey = newArr[findIndex].actions.findIndex(
					(el: { key: any }) => el.key === actionKey
				);
				for (const bKey in data[key].actions[i]?.metadata) {
					const finalIndex = newArr[findIndex].actions[
						actionIndexKey
					]?.metadata?.findIndex((el: { key: string }) => el.key === bKey);

					if (finalIndex !== -1 && finalIndex !== undefined) {
						newArr[findIndex].actions[actionIndexKey].metadata[finalIndex][
							'default'
						] = data[key].actions[i].metadata[bKey];
					}
				}
			}
		}

		handleSettingFormState(newArr);
		setSteps(newArr);
	};

	const intialSetup = (item: any) => {
		// Destructure properties from the 'item' object with default values
		const {
			design,
			configurations,
			_id,
			type,
			nodes: itemNodes = [],
		} = item ?? {};
		// Destructure properties from the 'design' object with default values
		const { nodes: designNodes = [], edges: designEdges = [] } = design ?? {};

		const publishName = item.name ? item.name : onboardingNameWithTime;

		const allEdges = [];
		for (let i = 0; i < designEdges.length; i++) {
			const payload = {
				...designEdges[i],
				...(typeof designEdges[i].targetHandle === 'object' && {
					targetHandle: null,
				}),
			};
			allEdges.push(payload);
		}

		// Find the authentication item in the itemNodes array
		const authItem = itemNodes.find(
			(node: any) => node.stepId === 'authentication'
		);

		// Get the _id of the authentication item or set it to an empty string if not found
		const authId = authItem ? authItem._id : '';

		// Create a new array called updatedItemNodes using the map method
		const updatedItemNodes = itemNodes.map((node: any) => {
			// Map the parentId array of the current node and update 'authId' to 'face-auth-0' if necessary
			const parentId = node.parentId.map((el: string) =>
				el === authId ? authId || 'face-auth-0' : el
			);
			// Create a new object with the same properties as the current node, but with updated 'parentId'
			return { ...node, parentId };
		});

		// Extract reminder settings from configurations
		const {
			reminderSettingTrigger,
			reminderSettingEmailSubject,
			reminderSettingEmailMessage,
			reminderSettingTextMessage,
			reminderDeadline = '',
		} = configurations?.deliveryMethod?.actions?.find(
			(el: any) => el.actionId === 'reminderSetting'
		)?.metadata ?? {};

		setDeadline({ value: reminderDeadline, errorMessage: '' });
		// Extract publishAdditionalSetting settings from configurations
		const { publishRedirect = '', publishWebhook = '' } =
			configurations?.publish?.actions?.find(
				(el: any) => el.actionId === 'publishAdditionalSetting'
			)?.metadata ?? {};

		// Initialize an object to store reminder email settings
		const reminderEmailPayload = {
			messageInput: false,
			emailInput: false,
		};

		// Check if email subject and message are provided
		if (reminderSettingEmailSubject && reminderSettingEmailMessage) {
			reminderEmailPayload.emailInput = true;
		}

		// Check if text message is provided
		if (reminderSettingTextMessage) {
			reminderEmailPayload.messageInput = true;
		}
		// Set email message reminder settings
		setEmailMessageReminder(reminderEmailPayload);

		// Extract the 'value' property from reminder setting trigger
		const { value } = reminderSettingTrigger ?? {};
		let enableReminderSetting = false;
		if (value) {
			if (
				value.join(',').includes('/') &&
				// Shahbaaz: Checking old month because this library doesn't support old dates to show
				isCurrentMonthOrNextMonthExist(value)
			) {
				setSelectedMultipleDate(value);
			} else {
				setReminderDays({ checked: false, days: value });
			}
			enableReminderSetting = true;
		}

		// Check if reminder setting trigger is provided and enable it
		setPipelineSettingFormState(prev => ({
			...prev,
			enableReminderSetting: enableReminderSetting,
			publishName,
		}));

		// Initialize an array to store all nodes with any type as type is not confirmed yet
		const allNodes = [];
		const filtered: any = [];
		const newVal: any = {};
		const newUnitPriceValue: { [key: string]: string } = {};
		const newUnitPriceToggleOn: { [key: string]: boolean } = {};
		// Iterate over designNodes and process them
		for (let i = 0; i < designNodes.length; i++) {
			const data = designNodes[i];
			const payload = {
				...data,
				parentId: updatedItemNodes.find((el: any) => el._id === data.id)
					?.parentId,
			};
			const { stepId } = designNodes[i] ?? {};
			const { conditions } = payload ?? {};

			if (stepId === 'dynamicForm' && conditions?.length) {
				conditions.forEach((condition: any) => {
					const { key, status, target } = condition ?? {};
					if (key) {
						if (status) {
							setDynamicFormStatus((pre: any) => ({
								...pre,
								[key]: StatusOptions.find(
									statusOption => statusOption.value === status
								),
							}));
						}
						if (target) {
							setDynamicFormPaths((pre: any) => ({
								...pre,
								[key]: PathOptions[1],
							}));
						}
					}
				});
			}

			allNodes.push(payload);
		}

		// Process steps and actions
		steps.forEach(({ actions, key }) => {
			if (selectedCheckbox[key]) filtered.push(...actions);
		});
		filtered.push(kybObjectStep);

		// Initialize arrays for configured steps and actions
		const allConfuguredStep = [];
		const actions = [];

		// Iterate over itemNodes and process them
		for (let i = 0; i < updatedItemNodes.length; i++) {
			const obj = updatedItemNodes[i];

			if (!newVal[obj._id]) {
				newVal[obj._id] = {};
			}

			if (obj.key === 'kybForm') {
				// Process kybForm actions
				const kybObject = obj.actions.find(
					(el: any) => el.actionId === 'kybForm'
				);

				const payload = { elements: kybObject.metadata.questions };
				Object.assign(newVal[obj._id], payload);
			} else if (obj.key === 'dynamicForm') {
				Object.assign(
					newVal[obj._id],
					obj.actions?.[0]?.metadata?.questions ?? {}
				);
			} else if (obj.key === proofVerification) {
				Object.assign(newVal[obj._id], obj.actions[0]?.metadata ?? {});
			} else {
				const actionMetadata = obj?.actions?.[0]?.metadata;
				if (
					actionMetadata?.payInPayOut === 'payIn' &&
					actionMetadata?.payInUnitPricing
				) {
					newUnitPriceToggleOn[obj._id] = !!actionMetadata.payInUnitPricing;
					newUnitPriceValue[obj._id] = actionMetadata.payInUnitPricing;
				}
				Object.assign(newVal[obj._id], obj.actions[0].metadata);
			}

			// Check if the step is in ConfigurableSteps
			if (
				(isStage ? StageConfigurableSteps : ConfigurableSteps).includes(
					obj.stepId
				)
			) {
				const payload = {
					id: obj._id,
					configed: true,
					stepId: obj.stepId,
				};
				allConfuguredStep.push(payload);
			}

			// Find and store actions
			const found = filtered.find((item: any) => item.key === obj.key);
			if (found) actions.push(found);
		}

		// Set selected onboarding and various state variables
		setSelectedOnboarding({ id: _id, type: type, authId });
		setNodes(allNodes);
		setEdges(allEdges);
		handleSteps(item.configurations, structuredClone(steps));
		setAddedActions(actions);
		setComplexFlowConfiged(allConfuguredStep);
		setComplexFlowSetting({
			...newVal,
			publishName,
			publishRedirect,
			publishWebhook,
		});
		resetSelectedRadioLabel();
		setNavigate('complex-flow');
		navigate(`${PIPELINE}${FLOW}`);
		setUnitPriceInputValue(newUnitPriceValue);
		setIsUnitPriceToggleOn(newUnitPriceToggleOn);
	};

	const validateConditions = (dataArray: IComplexNode[]) => {
		const id: string[] = [];
		for (const obj of dataArray) {
			if (obj.key === 'kybForm') {
				const allChecked = complexSettingForm[obj.id]?.elements.filter(
					(el: any) => el.checked
				);
				if (allChecked?.length === 0) {
					id.push(obj.id);
				}
			}
			if (
				obj.conditions.length > 0 &&
				RequiredValidationKeysForNodes[obj.stepId]
			) {
				for (const condition of obj.conditions) {
					if (!Object.prototype.hasOwnProperty.call(condition, 'then')) {
						id.push(obj.id);
					}

					const requiredAndOrKeys =
						RequiredValidationKeysForNodes[obj.stepId as any];
					if (!requiredAndOrKeys) {
						continue;
					}

					if (condition.and) {
						for (const andCondition of condition.and) {
							for (const key of requiredAndOrKeys) {
								if (!Object.prototype.hasOwnProperty.call(andCondition, key)) {
									id.push(obj.id);
								}
							}
						}
					}

					if (condition.or) {
						for (const orCondition of condition.or) {
							for (const key of requiredAndOrKeys) {
								if (!Object.prototype.hasOwnProperty.call(orCondition, key)) {
									id.push(obj.id);
								}
							}
						}
					}
				}
			}
		}
		const resp = {
			valid: id.length <= 0,
			id: id,
		};

		return resp;
	};
	return {
		onNodesDelete,
		onDragOver,
		onConnect,
		onDrop,
		onNodesChange,
		onEdgesChange,
		setReactFlowInstance,
		edges,
		nodes,
		reactFlowWrapper,
		handleDeleteEdges,
		intialSetup,
		validateConditions,
	};
};
