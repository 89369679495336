import { DateTime } from 'luxon';

export const getDate = (date: string | number | Date, format?: string) => {
	switch (typeof date) {
		case 'number':
			return DateTime.fromMillis(date).toFormat(format ?? 'MMM dd, yyyy');
		case 'string':
			return DateTime.fromISO(date).toFormat(format ?? 'MMM dd, yyyy');
		default:
			// eslint-disable-next-line no-case-declarations
			const formatToString = new Date(date).toISOString();
			return DateTime.fromISO(formatToString).toFormat(format ?? 'yyyy-MM-dd');
	}
};

export const getDateTime=(date: string)=>{
	const formattedDate = DateTime.fromISO(date).toFormat('EEEE, LLLL dd · h:mma');
	return formattedDate
}

export const getUtcMilliSecond = (
	userDate: Date | string,
	type?: 'start' | 'end'
) => {
	let date = userDate;
	if (typeof date === 'object') {
		date = (userDate as Date).toISOString();
	}

	if (type === 'start') {
		return DateTime.fromISO(date).startOf('day').toUTC().toMillis();
	} else if (type === 'end') {
		return DateTime.fromISO(date).endOf('day').toUTC().toMillis();
	}

	return DateTime.fromISO(date).toUTC().toMillis();
};

export const getOffDate = (userDate: Date | string, type?: 'start' | 'end') => {
	let date = userDate;
	if (typeof date === 'object') {
		date = (userDate as Date).toISOString();
	}

	if (type === 'start') {
		return DateTime.fromISO(date).startOf('day').toUTC().toISO();
	} else if (type === 'end') {
		return DateTime.fromISO(date).endOf('day').toUTC().toISO();
	}
	return DateTime.fromISO(date).toUTC().toISO();
};

export const getDateWithTime = (
	date: string | number | Date,
	format?: string
) => {
	const convertedDate = new Date(date);
	if (/Invalid|invalid/.test(convertedDate.toString())) {
		return '--';
	} else {
		return DateTime.fromMillis(convertedDate.getTime()).toFormat(
			format ?? 'MMM dd, yyyy hh:mm a'
		);
	}
};

export const getESTDateWithTime = (date: Date) => {
	const convertedDate = new Date(date);
	if (/Invalid|invalid/.test(convertedDate.toString())) {
		return '--';
	} else {
		const estDate = convertedDate.toLocaleString('en-US', {
			timeZone: 'America/Panama',
			month: 'short',
			day: 'numeric',
			year: 'numeric',
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
			timeZoneName: 'short',
			second: '2-digit',
		});
		return estDate;
	}
};

export const getTime = (date: string | number | Date, format?: string) => {
	switch (typeof date) {
		case 'number':
			return DateTime.fromMillis(date).toFormat('hh:mm a');
		case 'string':
			return DateTime.fromISO(date).toFormat('hh:mm a');
		default:
			// eslint-disable-next-line no-case-declarations
			const formatToString = new Date(date).toISOString();
			return DateTime.fromISO(formatToString).toFormat(format ?? 'yyyy-MM-dd');
	}
};

export const getDifferenceInDate = (date1: any, date2: any) => {
	const diffTime = Math.abs(date2 - date1);
	return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

export const formatDate = (inputDate: string) => {
	const date = new Date(inputDate);
	const options = { year: 'numeric', month: 'short' };
	return date.toLocaleString('en-US', options as any);
};

export const isValidDate = (dateString: string) => {
	const regEx = /^\d{4}-\d{2}-\d{2}$/;
	return dateString.match(regEx) != null;
};
export const getCurrentDate = () => {
	const now = new Date();
	const monthNames = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];
	const formattedDate = `${monthNames[now.getMonth()]} ${String(
		now.getDate()
	).padStart(2, '0')}, ${now.getFullYear()}`;
	return formattedDate;
};

export const isCurrentMonthOrNextMonthExist = (dateStrings: string[]) => {
	const currentDate = new Date();
	const currentYear = currentDate.getFullYear();
	const currentMonth = currentDate.getMonth() + 1; // Adding 1 to get the actual month

	for (const dateString of dateStrings) {
		const date = new Date(dateString);
		const dateYear = date.getFullYear();
		const dateMonth = date.getMonth() + 1;

		if (currentYear === dateYear && currentMonth === dateMonth) {
			return true; // Found a date in the current year and month
		}

		if (
			dateYear > currentYear ||
			(dateYear === currentYear && dateMonth > currentMonth)
		) {
			return true; // Found a date in a future year or month
		}
	}

	return false; // No dates in the current month or next further month
};

export const formateMonthDayYear = (date: string) => {
	const dateObj = new Date(date);

	if (/Invalid|invalid/.test(dateObj.toString())) {
		return 'NA';
	}
	const formattedDate = getDate(dateObj.getTime(), 'MM-dd-yyyy').replace(
		/\//g,
		'-'
	);

	return formattedDate;
};

export function convertDateFormat(date: string | null) {
	// Check if date is null, undefined, or falsy
	if (!date || /Invalid|invalid/.test(date.toString())) {
		return 'NA';
	}
	// Split the input date string by the hyphen
	const [year, month, day] = date.split('-');

	// Return the date in the format MM-DD-YYYY
	return `${month}-${day}-${year}`;
}

export function toTitleCase(str: string) {
	if(!str) return '';
	return str.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
}

export function fromCamelCaseToTitleCase(text: string){
	if(!text) return '';
	const result = text.replace(/([A-Z])/g, " $1");
	return result.charAt(0).toUpperCase() + result.slice(1);
}
