import { useMemo } from 'react';

import DashboardPage from 'views/dashboard-analytics/dashboard-page';
import { ROUTES } from './constants';
import Pipeline from 'views/pipeline/pipeline';
import OnboardingFlow from 'views/onboarding-flow/onboarding-flow';
import Sessions from 'views/sessions/sessions';
import Funds from 'views/funds/funds';
import FundDetails from 'views/funds-pages/funds-Details';
import ESignDashboard from 'views/simplici-sign-dashboard/sign-dashboard';
import SignedDoc from 'views/signed-doc/signed-doc';
import SignedDocPacketsInbox from 'views/signed-doc-packets-inbox/signed-doc-packets-inbox';
import SignedDocInbox from 'views/signed-doc-inbox/signed-doc-inbox';
import Transactions from 'views/transactions/transactions';
import UserRoles from 'views/user-roles/user-roles';
import Integrate from 'views/integrate/integrate';
import Document from 'views/document/document';
import Analytics from 'views/analytics/analytics';
import SupportAgent from 'views/support-agent/support-agent';
import Billing from 'views/settings/billing/billing';
import Credential from 'views/credential/credential';
import SubAccount from 'views/sub-account/sub-account';
import ChequeFraud from 'views/cheque-fraud/cheque-fraud';
import { Deals } from 'views/deals';
import Settings from 'views/settings/settings';
import Auth0 from 'views/auth/auth';
import { Events } from 'views/events';
import EsignDraft from 'views/esign-draft/esign-draft';

const {
	CREDENTIALS,
	SETTINGS,
	USER_ROLES,
	SESSIONS,
	FUNDS,
	SIGNED_DOC,
	SIGNED_DOC_INBOX,
	BILLING,
	INTEGRATE,
	TRANSACTIONS,
	DASHBOARD,
	API,
	ANALYTICS,
	SUPPORT_AGENT,
	SIMPLICI_SIGN_DASHBOARD,
	INBOX,
	PIPELINE,
	FLOW,
	CHEQUE_FRAUD,
	SUB_ACCOUNT,
	DEALS,
	AUTH,
	EVENTS,
	ESIGN_DRAFT
} = ROUTES;

interface IRoutesModuleProps {
	fundsLoaded?: any;
	settingsLoaded?: any;
	IsCredentials?: boolean;
	userRoleLoading?: any;
	userRolesLoaded?: any;
	settingsLoading?: any;
}

export const useRoutesModule = ({
	fundsLoaded,
	IsCredentials,
}: IRoutesModuleProps) => {
	const ALL_ROUTES_MODULE = useMemo(() => {
		const ALL_ROUTES_MODULE = {
			dashboard: {
				path: DASHBOARD,
				element: <DashboardPage />,
			},
			onboarding: {
				path: PIPELINE,
				children: [
					{
						element: <Pipeline />,
						index: true,
					},
					{
						path: `${PIPELINE}${FLOW}`,
						element: <OnboardingFlow />,
					},
				],
			},
			session: {
				path: SESSIONS,
				element: <Sessions />,
			},
			funds: {
				path: FUNDS,
				children: [
					{
						element: <Funds loaded={fundsLoaded} />,
						index: true,
					},
					{
						path: `${FUNDS}/:id`,
						element: <FundDetails />,
					},
				],
			},
			signDashboard: {
				path: SIMPLICI_SIGN_DASHBOARD,
				element: <ESignDashboard />,
			},
			template: {
				path: SIGNED_DOC,
				element: <SignedDoc />,
			},
			inbox: {
				path: INBOX,
				element: <SignedDocPacketsInbox />,
			},
			sent: {
				path: SIGNED_DOC_INBOX,
				element: <SignedDocInbox />,
			},
			transaction: {
				path: TRANSACTIONS,
				element: <Transactions />,
			},
			generalSettings: {
				path: SETTINGS,
				element: <Settings />,
			},
			developersKeys: {
				path: CREDENTIALS,
				element: <Credential loading={IsCredentials} />,
			},
			userRoles: {
				path: USER_ROLES,
				element: <UserRoles />,
			},
			subAccount: {
				path: SUB_ACCOUNT,
				element: <SubAccount />,
			},
			embedWeb: {
				path: INTEGRATE,
				element: <Integrate />,
			},
			apiDocs: {
				path: API,
				element: <Document />,
			},
			userAnalytics: {
				path: ANALYTICS,
				element: <Analytics />,
			},
			supportAgent: {
				path: SUPPORT_AGENT,
				element: <SupportAgent />,
			},
			billing: {
				path: BILLING,
				element: <Billing />,
			},
			checkDefense: {
				path: CHEQUE_FRAUD,
				element: <ChequeFraud />,
			},
			deals: {
				path: DEALS,
				element: <Deals />,
			},
			auth: {
				path: AUTH,
				element: <Auth0 />,
			},
			events: {
                path: EVENTS,
                element: <Events />,
            },
			draft: {
                path: ESIGN_DRAFT,
                element: <EsignDraft />,
            },
		};
		return ALL_ROUTES_MODULE;
	}, [IsCredentials, fundsLoaded]);

	return { ALL_ROUTES_MODULE };
};

// Awadhesh: lazy loading

// import { lazy, useMemo } from 'react';
// import { ROUTES } from './constants';

// const {
// 	CREDENTIALS,
// 	SETTINGS,
// 	USER_ROLES,
// 	SESSIONS,
// 	FUNDS,
// 	SIGNED_DOC,
// 	SIGNED_DOC_INBOX,
// 	BILLING,
// 	INTEGRATE,
// 	TRANSACTIONS,
// 	DASHBOARD,
// 	API,
// 	ANALYTICS,
// 	SUPPORT_AGENT,
// 	SIMPLICI_SIGN_DASHBOARD,
// 	INBOX,
// 	PIPELINE,
// 	FLOW,
// 	CHEQUE_FRAUD,
// 	SUB_ACCOUNT,
// } = ROUTES;

// interface IRoutesModuleProps {
// 	fundsLoaded?: any;
// 	credentialsLoading?: any;
// 	credentialsLoaded?: any;
// }

// const retry = (
// 	fn: () => Promise<unknown>,
// 	retriesLeft = 5,
// 	interval = 1000
// ) => {
// 	return new Promise((resolve, reject) => {
// 		fn()
// 			.then(resolve)
// 			.catch(error => {
// 				setTimeout(() => {
// 					if (retriesLeft === 1) {
// 						// reject('maximum retries exceeded');
// 						reject(error);
// 						return;
// 					}
// 					// Passing on "reject" is the important part
// 					retry(fn, retriesLeft - 1, interval).then(resolve, reject);
// 				}, interval);
// 			});
// 	});
// };

// const DashboardPage = lazy(
// 	() =>
// 		retry(() => import(/* webpackChunkName: "dashboard" */'../views/dashboard-analytics/dashboard-page')) as any
// );
// const Pipeline = lazy(
// 	() => retry(() => import(/* webpackChunkName: "pipeline" */'../views/pipeline/pipeline')) as any
// );
// const OnboardingFlow = lazy(
// 	() => retry(() => import(/* webpackChunkName: "onboarding-flow" */'../views/onboarding-flow/onboarding-flow')) as any
// );
// const Sessions = lazy(
// 	() => retry(() => import(/* webpackChunkName: "sessions" */'../views/sessions/sessions')) as any
// );
// const Funds = lazy(() => retry(() => import(/* webpackChunkName: "funds" */'../views/funds/funds')) as any);
// const FundDetails = lazy(
// 	() => retry(() => import(/* webpackChunkName: "funds-Details" */'../views/funds-pages/funds-Details')) as any
// );
// const ESignDashboard = lazy(
// 	() =>
// 		retry(
// 			() => import(/* webpackChunkName: "sign-dashboard" */'../views/simplici-sign-dashboard/sign-dashboard')
// 		) as any
// );
// const SignedDoc = lazy(
// 	() => retry(() => import(/* webpackChunkName: "signed-doc" */'../views/signed-doc/signed-doc')) as any
// );
// const SignedDocPacketsInbox = lazy(
// 	() =>
// 		retry(
// 			() => import(/* webpackChunkName: "signed-doc-packets-inbox" */'../views/signed-doc-packets-inbox/signed-doc-packets-inbox')
// 		) as any
// );
// const SignedDocInbox = lazy(
// 	() => retry(() => import(/* webpackChunkName: "signed-doc-inbox" */'../views/signed-doc-inbox/signed-doc-inbox')) as any
// );
// const Transactions = lazy(
// 	() => retry(() => import(/* webpackChunkName: "transactions" */'../views/transactions/transactions')) as any
// );
// const Settings = lazy(
// 	() => retry(() => import(/* webpackChunkName: "settings" */'../views/settings/settings')) as any
// );
// const Credential = lazy(
// 	() => retry(() => import(/* webpackChunkName: "credential" */'../views/credential/credential')) as any
// );
// const UserRoles = lazy(
// 	() => retry(() => import(/* webpackChunkName: "user-roles" */'../views/user-roles/user-roles')) as any
// );
// const SubAccount = lazy(
// 	() => retry(() => import(/* webpackChunkName: "sub-account" */'../views/sub-account/sub-account')) as any
// );
// const Integrate = lazy(
// 	() => retry(() => import(/* webpackChunkName: "integrate" */'../views/integrate/integrate')) as any
// );
// const Document = lazy(
// 	() => retry(() => import(/* webpackChunkName: "document" */'../views/document/document')) as any
// );
// const Analytics = lazy(
// 	() => retry(() => import(/* webpackChunkName: "analytics" */'../views/analytics/analytics')) as any
// );
// const SupportAgent = lazy(
// 	() => retry(() => import(/* webpackChunkName: "support-agent" */'../views/support-agent/support-agent')) as any
// );
// const Billing = lazy(
// 	() => retry(() => import(/* webpackChunkName: "billing" */'../views/settings/billing/billing')) as any
// );
// const ChequeFraud = lazy(
// 	() => retry(() => import(/* webpackChunkName: "cheque-fraud" */'../views/cheque-fraud/cheque-fraud')) as any
// );

// export const useRoutesModule = ({
// 	fundsLoaded,
// 	credentialsLoading,
// 	credentialsLoaded,
// }: IRoutesModuleProps) => {
// 	const ALL_ROUTES_MODULE = useMemo(() => {
// 		const ALL_ROUTES_MODULE = {
// 			dashboard: {
// 				path: DASHBOARD,
// 				element: <DashboardPage />,
// 			},
// 			onboarding: {
// 				path: PIPELINE,
// 				children: [
// 					{
// 						element: <Pipeline />,
// 						index: true,
// 					},
// 					{
// 						path: `${PIPELINE}${FLOW}`,
// 						element: <OnboardingFlow />,
// 					},
// 				],
// 			},
// 			session: {
// 				path: SESSIONS,
// 				element: <Sessions />,
// 			},
// 			funds: {
// 				path: FUNDS,
// 				children: [
// 					{
// 						element: <Funds loaded={fundsLoaded} />,
// 						index: true,
// 					},
// 					{
// 						path: `${FUNDS}/:id`,
// 						element: <FundDetails />,
// 					},
// 				],
// 			},
// 			signDashboard: {
// 				path: SIMPLICI_SIGN_DASHBOARD,
// 				element: <ESignDashboard />,
// 			},
// 			template: {
// 				path: SIGNED_DOC,
// 				element: <SignedDoc />,
// 			},
// 			inbox: {
// 				path: INBOX,
// 				element: <SignedDocPacketsInbox />,
// 			},
// 			sent: {
// 				path: SIGNED_DOC_INBOX,
// 				element: <SignedDocInbox />,
// 			},
// 			transaction: {
// 				path: TRANSACTIONS,
// 				element: <Transactions />,
// 			},
// 			generalSettings: {
// 				path: SETTINGS,
// 				element: <Settings />,
// 			},
// 			developersKeys: {
// 				path: CREDENTIALS,
// 				element: (
// 					<Credential loading={credentialsLoading} loaded={credentialsLoaded} />
// 				),
// 			},
// 			userRoles: {
// 				path: USER_ROLES,
// 				element: <UserRoles />,
// 			},
// 			subAccount: {
// 				path: SUB_ACCOUNT,
// 				element: <SubAccount />,
// 			},
// 			embedWeb: {
// 				path: INTEGRATE,
// 				element: <Integrate />,
// 			},
// 			apiDocs: {
// 				path: API,
// 				element: <Document />,
// 			},
// 			userAnalytics: {
// 				path: ANALYTICS,
// 				element: <Analytics />,
// 			},
// 			supportAgent: {
// 				path: SUPPORT_AGENT,
// 				element: <SupportAgent />,
// 			},
// 			billing: {
// 				path: BILLING,
// 				element: <Billing />,
// 			},
// 			checkDefense: {
// 				path: CHEQUE_FRAUD,
// 				element: <ChequeFraud />,
// 			},
// 		};
// 		return ALL_ROUTES_MODULE;
// 	}, [credentialsLoaded, credentialsLoading, fundsLoaded]);

// 	return { ALL_ROUTES_MODULE };
// };
