import { Fragment, useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Loader } from '@storybook';

import { API_URL } from 'constant';
import { getDate } from 'utils';
import { useNetwork } from 'hooks';
import { useFormatNumber } from 'utils';
import { BillingSummary, useGetInviteOnboardingPayment } from '../../store';
import { BillingDetailsState } from './stores';

import './billing-details.scss';

export const BillingDetails = () => {
	const [billingDetailData, setBillingDetailData] =
		useRecoilState(BillingDetailsState);
	const { data: summary } = useRecoilValue(BillingSummary);

	const { get: getBillingData, loading } = useNetwork();
	const { numberDecimal } = useFormatNumber();
	const { invitedUsers } = useGetInviteOnboardingPayment();


	const billingAmount = useMemo(
		() => summary?.totalPrice * invitedUsers,
		[invitedUsers, summary?.totalPrice]
	);

	useEffect(() => {
		if (!billingDetailData)
			getBillingData(API_URL.BILLING).then(res => {
				if (res?.data) {
					if (res?.data && res?.data.length > 0)
						setBillingDetailData(res.data[0]);
				}
			});
		// eslint-disable-next-line
	}, []);


	const renderMainComponent = useMemo(() => {
		const totalAmount = billingDetailData?.totalAmount ?? 0;
		if (loading) {
			return <Loader type="loader" dimension={40} />;
		} else
			return (
				<Fragment>
					<div className="Billing--Details__inner">
						<div className="Billing--Details__inner__label">
							Next Billing Date
						</div>
						<div className="Billing--Details__inner__value">
							{billingDetailData?.endDate ? getDate(billingDetailData?.endDate) : '--'}
						</div>
					</div>
					<div className="Billing--Details__addition_wrapper">
						<div className="Billing--Details__inner">
							<div className="Billing--Details__inner__label">Previous Due</div>
							<div className="Billing--Details__inner__value">
								${numberDecimal(totalAmount)}
							</div>
						</div>
						<div className="Billing--Details__addition">+</div>
						<div className="Billing--Details__inner">
							<div className="Billing--Details__inner__label">Current Due</div>
							<div className="Billing--Details__inner__value">
								${numberDecimal(billingAmount ?? 0)}
							</div>
						</div>
					</div>

					<div className="Billing--Details__inner">
						<div className="Billing--Details__inner__label">Total Due</div>
						<div className="Billing--Details__inner__value">
							${numberDecimal(totalAmount + billingAmount)}
						</div>
					</div>
				</Fragment>
			);
	}, [billingAmount, billingDetailData?.endDate, billingDetailData?.totalAmount, loading, numberDecimal]);
	return <div className="Billing--Details">{renderMainComponent}</div>;
};
