export const labelData: { [key: string]: string } = {
	allId: 'KYC (Know your customer)',
	amlVerification: 'AML (Anti-Money Laundering)',
	accreditationVerify: 'Accreditation Verification',
	signAgreementVerification: 'Sign Agreement',
	fundInvestmentVerification: 'Fund Investment',
	formAction: 'Questionnaire',
	kybVerification: 'KYB (Know your business)',
	liveness: 'Liveness',
	verify: "Scan & verify passport, national photo id or driver's license",
	SSN: 'SSN(Social security number) verification',
	radio: 'Radio option group',
	kybForm: 'Entity/KYB Document Requirements',
	accountForm: 'Individual or Business Questionnaire.',
	address: 'Address verification',
	proofVerification: 'Document Review',
	authentication: 'Authentication',
	successScreenCompletion: "Success Screen",
	eventVerification: 'Event'
};

export const DataTranferFile = {
	ApplicationType: 'application/complex-flow',
};

export const ActionList: Record<string, string> | any = {
	kyb: 'kybVerification',
	kyc: 'allId',
	form: 'formAction',
	signAgreement: 'signAgreementVerification',
	aml: 'amlVerification',
	amlVerification: 'aml',
	fund: 'fundInvestmentVerification',
	accreditation: 'accreditationVerify',
	kybVerification: 'kyb',
	allId: 'kyc',
	formAction: 'dynamicForm',
	accreditationVerify: 'accreditation',
	signAgreementVerification: 'signAgreement',
	fundInvestmentVerification: 'fundInvestment',
	kybForm: 'kybForm',
	accountForm: 'form',
	verify: 'idv',
	address: 'addressVerification',
	SSN: 'ssn',
	proofReading: 'proofVerification',
	proofVerification: 'proofReading',
	authentication: 'authentication',
	successScreenCompletion: 'successScreen',
	successScreen: 'successScreenCompletion',
	eventVerification: 'event',
	event: 'eventVerification',
};

// Arun kumar: kyb be added because by default we need to show green configed
export const ConfigurableSteps = [
	'kyc',
	'fundInvestment',
	'kyb',
	'signAgreement',
	'accreditation',
	'successScreenCompletion',
	'successScreen',
	'dynamicForm',
	'amlVerification',
	'aml',
	'authentication',
	'authentication',
];

// Arun kumar: kyb be added because by default we need to show green configed
export const StageConfigurableSteps = [
	'kyc',
	'fundInvestment',
	'aml',
	'amlVerification',
	'kyb',
	'successScreen',
	'successScreenCompletion',
	'signAgreement',
	'accreditation',
	'dynamicForm',
	'authentication',
	'authentication',
];

export const VerifyOptions = [
	{
		label: 'Approve',
		value: 'approve',
	},
	{
		label: 'Manual review',
		value: 'manualReview',
	},
	{
		label: 'Reject',
		value: 'reject',
	},
];

export const OperatorOptions = [
	{
		label: 'Equals to',
		value: '===',
	},
	{
		label: 'Greater than',
		value: '>',
	},
	{
		label: 'Greater than or equal to',
		value: '>=',
	},
	{
		label: 'Less than',
		value: '<',
	},
	{
		label: 'Less than or equal to',
		value: '<=',
	},
];

export const OperatorKey = {
	'===': 'Equals to',
	'>': 'Greater than',
	'>=': 'Greater than or equal to',
	'<': 'Less than',
	'<=': 'Less than or equal to',
};

export const KybSelectAnswer = [
	{
		label: 'Match',
		value: 'match',
	},
	{
		label: 'No Match',
		value: 'no-match',
	},
];

export const AmlSelectAnswer = [
	{
		label: 'True',
		value: 'true',
	},
	{
		label: 'False',
		value: 'false',
	},
];

export const AccreditationSelectAnswer = [
	{
		label: 'True',
		value: 'true',
	},
	{
		label: 'False',
		value: 'false',
	},
];

export const Ids: any = {
	liveness: 'liveliness',
	SSN: 'ssn',
	address: 'addressVerification',
};

export const QuestionnaireStep = {
	New: 'NEW',
	Old: 'OLD',
};

export const kybObjectStep = {
	_id: '653a299e5bbce8a6047f88e9',
	label: 'Questionnaire',
	description: 'General Questionare',
	key: 'dynamicForm',
	price: 0,
	metadata: [],
	stepKey: 'form',
	nodeId: 'node-f031199c-bff7-4e76-a878-cde657fa39fe',
};

export const RequiredValidationKeysForNodes: any = {
	aml: ['operand', 'param'],
	kyc: ['operand', 'param', 'operator'],
	accreditation: ['operand', 'param', 'operator'],
	kyb: ['operand', 'param'],
};

export const SuccessNode = {
	STEP_ID: 'successScreen',
	FORM_ID: 'form',
	FORM_INDIVIDUAL: '_multipleChoice_Individual',
	FORM_BUSINESS: '_multipleChoice_Business',
	ERROR_MESSAGE : 'The Success Screen cannot be incorporated within a conditional flow.'
};
