import {
	AutoFillLocation,
	DateTimePicker,
	Input,
	IOption,
	ReactDropdown,
	TextArea,
} from '@storybook';
import { UploadDocs } from 'components';
import { useNotification } from 'hooks';
import { ChangeEvent, FC, useCallback } from 'react';
import { convertFileToBase64 } from 'utils';
import '../../events.scss';
import { IEventForm } from 'views/events/events';

interface ICreateEvent {
    eventForm: IEventForm
	setEventForm: (prev: any) => void;
	onCreateEvent: () => void;
}

const initialEvents: IOption[] = [
	{ value: 'both', label: 'Biometric and QR' },
	{ value: 'any', label: 'Biometric or QR' },
	{ value: 'biometric', label: 'Biometric' },
	{ value: 'qr', label: 'QR' },
];

export const CreateEvent: FC<ICreateEvent> = ({
    eventForm,
	setEventForm,
	onCreateEvent,
}) => {
	const { errorNotification } = useNotification();

	const handleChangeSelect = (option: IOption, name: string) => {
		setEventForm((prev: any) => ({ ...prev, [name]: option }));
	};

	const handleOnChange = (
		e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		const { name, value } = e.target;
		setEventForm((prev: any) => ({ ...prev, [name]: value }));
	};

	const handleAutoInputValue = (data: any) => {
		setEventForm((prev: any) => ({ ...prev, address: data?.enteredAddress }));
	};

	const handleFileChange = useCallback(async (event: File) => {
		const base64 = (await convertFileToBase64(event)) ?? '';
		if (!base64) {
			errorNotification('Something went wrong');
		}
		setEventForm((prev: any) => ({ ...prev, image: base64 as string }));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const onFileError = () => {
		return;
	};

	const handleRemoveImage = () => {
		setEventForm((prev: any) => ({ ...prev, image: '' }));
	};

	return (
		<form className="Events--form" onSubmit={onCreateEvent}>
			<Input
				label="Event Name"
				inputType="text"
				placeholder="Event Name"
				maxLength={100}
				inputName="name"
				handleChange={handleOnChange}
				value={eventForm.name}
				isRequired
				key="name"
			/>
			<DateTimePicker
				handleChange={handleOnChange}
				inputName="date"
				placeholder="Date & Time"
				value={eventForm.date}
				min={new Date().toISOString().slice(0, 16)}
			/>
			<AutoFillLocation
				label="Address"
				placeHolder="Address"
				isRequired
				handleOnChange={handleOnChange}
				value={eventForm.address}
				inputName="address"
				onSuccess={handleAutoInputValue}
				key="address"
			/>
            <ReactDropdown
				label="Authentication Method"
				options={initialEvents}
				value={eventForm.type}
				handleChangeSelect={(option: IOption) =>
					handleChangeSelect(option, 'type')
				}
			/>
			<TextArea
				label="Description"
				handleChange={handleOnChange}
				inputName="description"
				placeholder="Description"
				value={eventForm.description}
				key="description"
			/>
			<UploadDocs
				label="Image"
				handleChange={handleFileChange}
				isShowProgess
				accept=".png, .jpg, .jpeg"
				supportedFileDescription=".png, .jpg, .jpeg"
				onError={onFileError}
				key="image"
				uplaodedFile={eventForm?.image}
				handleRemove={handleRemoveImage}
			/>
		</form>
	);
};
