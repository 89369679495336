import { ChangeEvent, FC, Fragment, useCallback, useState } from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';
import Tippy from '@tippyjs/react';

import './auto-fill-input-location.scss';
import { REACT_APP_GOOGLE_MAP_SECRET_TOKEN as TOKEN } from 'envs';
import { ReactSwitch } from '@storybook/react-switch';
import { Input } from '@storybook/input';

interface Props {
	onSuccess: (e: ChangeEvent<HTMLInputElement>) => void;
	placeHolder?: string;
	onSearch?: boolean;
	onClear?: boolean;
	label?: string;
	value: string | undefined;
	handleOnChange: (e: ChangeEvent<HTMLInputElement>) => void;
	isRequired?: boolean;
	isError?: boolean;
	errorMessage?: string | undefined;
	isManualSearch?: boolean;
	isDisabled?: boolean;
	inputName?: string
}

/**
 * AutoFillLocation component
 *
 * @param {ChangeEvent<HTMLInputElement> } Props.onSuccess: Callback function triggered on successful location selection
 * @param {string} Props.placeHolder: Placeholder text for the input field
 * @param {boolean} Props.onSearch: Flag to enable search icon in the input field
 * @param {boolean} Props.onClear: Flag to enable clear icon in the input field
 * @param {string} Props.label: Label for the input field
 * @param {string} Props.value: Current value of the input field
 * @param {ChangeEvent<HTMLInputElement>} Props.handleOnChange: Callback function triggered on input field change
 * @param {boolean} Props.isRequired: Flag to indicate if the input field is required
 * @param {boolean} Props.isError: Flag to indicate if there is an error in the input field
 * @param {string} Props.errorMessage: Error message to be displayed
 * @param {string} Props.isManualSearch: Show manual search field
 *
 */

export const AutoFillLocation: FC<Props> = ({
	onSuccess,
	placeHolder,
	onSearch,
	onClear,
	label,
	value,
	handleOnChange,
	isError,
	errorMessage,
	isRequired = false,
	isManualSearch = false,
	isDisabled = false,
	inputName= ""
}: any) => {
	const [isAutoSearch, setAutoSearch] = useState(true);

	const { ref } = usePlacesWidget({
		apiKey: TOKEN,
		onPlaceSelected: (place: any) => {
			let city, state, country, postal_code, route, sublocality, village;

			for (let i = 0; i < place?.address_components.length; i++) {
				for (let j = 0; j < place.address_components[i]?.types?.length; j++) {
					const { long_name } = place.address_components[i];
					switch (place?.address_components[i].types[j]) {
						case 'route':
							route = long_name;
							break;
						case 'sublocality':
							sublocality = long_name;
							break;
						case 'neighborhood':
							village = long_name;
							break;

						case 'locality':
							city = long_name;
							break;
						case 'administrative_area_level_1':
							state = long_name;
							break;
						case 'country':
							country = long_name;
							break;
						case 'postal_code':
							postal_code = long_name;
							break;
					}
				}
			}

			const payload = {
				city: city ?? '',
				state: state ?? '',
				country: country ?? '',
				postal_code: postal_code ?? '',
				sublocality: sublocality ?? '',
				route: route ?? '',
				village: village ?? '',
				enteredAddress: (ref.current as any)?.value ?? '',
			};
			onSuccess?.(payload);
		},
		options: {
			types: ['geocode', 'establishment'],
		},
	});

	const handleOnClear = () => {
		if (ref.current) (ref.current as any).value = '';
		onClear();
	};

	const handleToggal = useCallback(() => {
		setAutoSearch(prev => !prev);
	}, []);

	const props = {
		...(label && { label }),
		...(value ? { value } : { value: '' }),
	};

	return (
		<Fragment>
			<div className="auto-fill-wrapper">
				<div className="auto-fill-wrapper__label-wrapper">
					<div className="auto-fill-wrapper__label-wrapper__label">
						{label}
						{isRequired && <span>*</span>}
					</div>
					{isManualSearch && (
						<div className="auto-fill-wrapper--right-label">
							<ReactSwitch
								id="address-search"
								checked={isAutoSearch}
								handleChange={handleToggal}
							/>
							<div className="auto-fill-wrapper__label-wrapper__label">
								Autosearch
							</div>
							<Tippy content="Turning it off means you'll enter addresses manually without suggestions.">
								<i className="ri-information-line"></i>
							</Tippy>
						</div>
					)}
				</div>
				<div
					className={`${
						!isAutoSearch
							? 'auto-fill-wrapper--show'
							: 'auto-fill-wrapper--hide'
					}`}
				>
					<div
						className={`auto-fill-wrapper__input-wrapper ${
							isError && errorMessage?.length
								? 'input__text-field input__text-field--error'
								: ''
						}`}
					>
						<input
							style={{ width: '100%' }}
							placeholder={placeHolder}
							ref={ref as any}
							{...props}
							onChange={handleOnChange}
							autoComplete="off"
							disabled={isDisabled}
							name={inputName}
						/>
						{onSearch && <i className="ri-search-line search-icon"></i>}
						{onClear && (
							<i onClick={handleOnClear} className="ri-close-circle-line"></i>
						)}

						{isError && errorMessage && (
							<span className="input__error">{errorMessage}</span>
						)}
					</div>
				</div>

				{!isAutoSearch && (
					<Input
						label=""
						inputType="text"
						placeholder="ABCXYZ Inc."
						maxLength={100}
						handleChange={handleOnChange}
						value={value}
						isError={isError}
						errorMessage={errorMessage}
						disabled={isDisabled}
					/>
				)}
			</div>
		</Fragment>
	);
};
